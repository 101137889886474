* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0;
  margin: 0;
}

p, h1, h2, h3, h4, h5, ul {
  margin: 0;
}

.h1, .h2, .h3, .h4, .h5 {
  margin: 0;
}

dl, dd, dt {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}
a:hover, a:active, a:focus, a:visited {
  text-decoration: none;
}

input, textarea {
  outline: none;
}
input:focus, textarea:focus {
  outline: none;
}

textarea {
  resize: none;
}
:root {
  --look-card-padding: 4px;
  --product-card-padding: 4px;
}
/*@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&display=swap');

@font-face {
    font-family: "Noe Display";
    src: local("Noe Display Bold"), local("Noe-Display-Bold"), 
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Bold.woff) format("woff"),
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Bold.eot) format("eot");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Noe Display";
    src: local("Noe Display Regular"), local("Noe-Display-Regular"), 
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Regular.woff) format("woff"),
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Regular.eot) format("eot");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Noe Display";
    src: local("Noe Display Medium"), local("Noe-Display-Medium"), 
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Medium.woff) format("woff"),
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Medium.eot) format("eot");
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: "Noe Display";
    src: local("Noe Display Regular Italic"), local("Noe-Display-Regular-Italic"), 
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Regular_Italic.woff) format("woff"),
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Regular_Italic.eot) format("eot");
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: "Noe Display";
    src: local("Noe Display Medium Italic"), local("Noe-Display-Medium-Italic"), 
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Medium_Italic.woff) format("woff"),
            url(https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Noe_Display_Medium_Italic.eot) format("eot");
    font-weight: 500;
    font-style: italic;
}
*/
@font-face {
  font-family: "Mencken";
  src: local("Mencken"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Bold.eot");
  src: local("HelveticaNeueCyr-Bold"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Bold.eot?#iefix") format("embedded-opentype"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Bold.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Bold.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  descent-override: 0%;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Light.eot");
  src: local("HelveticaNeueCyr-Light"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Light.eot?#iefix") format("embedded-opentype"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Light.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Light.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  descent-override: 0%;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Medium.eot");
  src: local("HelveticaNeueCyr-Medium"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Medium.eot?#iefix") format("embedded-opentype"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Medium.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Medium.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  descent-override: 0%;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Roman.eot");
  src: local("HelveticaNeueCyr-Roman"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Roman.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Roman.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  descent-override: 0%;
}
.flex-break-h {
  flex-basis: 100%;
  height: 0;
}

.clearfix {
  clear: both;
  display: block;
}
@-o-viewport {
  user-zoom: fixed;
}
@viewport {
  user-zoom: fixed;
}
.hidden {
  display: none;
}

html, body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * var(--scale, 100));
  font-family: Mencken;
}

body {
  position: relative;
  font-size: 0;
}

html.theme-light body {
  background-color: #fff;
  color: #000;
}

.theme-light {
  color: #000;
}

html.theme-dark body {
  background-color: #000;
  color: #fff;
}

.theme-dark {
  color: #fff;
}

html.modal body {
  overflow: hidden;
}

#root {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * var(--scale, 100));
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 1440px;
  overscroll-behavior: none;
}
@media screen and (max-width: 960px) {
  .gt-md {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .lt-md {
    display: none !important;
  }
}
.avatar {
  font-family: HelveticaNeue;
  font-size: 16px;
  display: inline-block;
  /*width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  line-height: 40px;*/
  border-radius: 50%;
  background-color: #eeb7be;
  text-align: center;
  color: #000;
  background-size: cover;
}

.user-handle {
  display: flex;
  align-items: center;
}
.user-handle p {
  margin-left: var(--look-card-padding);
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.user-handle.user-handle-reverse {
  flex-direction: row-reverse;
}
.user-handle.user-handle-reverse p {
  margin-right: var(--look-card-padding);
  margin-left: 0;
}
.user-handle.user-handle-clickable {
  cursor: pointer;
}
.dropdown {
  position: absolute;
  display: none;
  z-index: 10;
  padding: 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
}
.dropdown.active {
  display: block;
}
.global-crash {
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.global-crash h1 {
  font-family: Mencken;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}
.global-crash p {
  font-family: HelveticaNeue;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.grid-wrapper {
  max-width: 100%;
}

.grid {
  width: 100%;
  border-collapse: collapse;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}
.grid th {
  background-color: #fff;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  padding: 20px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 5;
  vertical-align: bottom;
}
.grid th.grid-var-slim {
  padding: 12px 8px;
}
.grid th.grid-section-end {
  border-right: 1px solid #999;
}
.grid thead tr {
  background-color: #fff;
}
.grid thead tr th {
  background-color: #fff;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.grid thead tr th:first-child {
  border-left: 1px solid #d8d8d8;
}
.grid thead tr th:last-child {
  border-right: 1px solid #d8d8d8;
}
.grid thead tr th.min-width {
  width: 1px;
}
.grid thead tr th.grid-nowrap {
  white-space: nowrap;
}
.grid td {
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  border: 1px solid #d8d8d8;
  padding: 18px;
  vertical-align: top;
}
.grid td.grid-var-slim {
  padding: 8px;
}
.grid td.min-width {
  width: 1px;
}
.grid td.grid-section-end {
  border-right: 1px solid #999;
}
.grid td.grid-nowrap {
  white-space: nowrap;
}
.grid tbody tr {
  background-color: #fff;
  transition: background-color 0.3s;
}
.grid tbody tr.clickable {
  cursor: pointer;
}
.grid tbody tr:hover {
  background-color: #fafafa;
}
.grid tbody tr.selected {
  background-color: #f0f0f0;
}
.grid th.expandable-th {
  padding-left: 0;
  padding-right: 0;
}
.grid td.expandable-col {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 1px;
}
.grid td.expandable-col .icon {
  transform: rotate(90deg);
  transition: transform 0.2s;
}
.grid tr.expanded td.expandable-col .icon {
  transform: rotate(180deg);
}
.grid tr.expanded-row {
  background-color: #fafafa;
}
.grid tr.summary-row {
  background-color: #f0f0f0;
}
.grid tr.summary-row:hover {
  background-color: #f0f0f0;
}
.grid tr.summary-row td {
  padding-top: 4px;
  padding-bottom: 4px;
}
.grid tr.summary-row td.grid-slim {
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (max-width: 960px) {
  .grid {
    display: block;
    background-color: transparent;
  }
  .grid thead {
    display: none;
  }
  .grid tbody {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }
  .grid tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #d8d8d8;
  }
  .grid tbody tr td {
    display: block;
    border: 0;
    padding: 10px;
  }
}
.icon {
  pointer-events: none;
}

.icon-svg-fill {
  fill: currentColor;
}

.icon-svg-stroke {
  fill: none;
  stroke: currentColor;
}
.side-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
  z-index: 100;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.side-dialog.active {
  display: flex;
  transform: translateX(0);
}
.side-dialog h3 {
  font-family: Mencken;
  font-size: 26px;
  font-weight: normal;
  margin-right: 30px;
}
@media screen and (min-width: 600px) {
  .side-dialog {
    left: auto;
    width: 400px;
    transform: translateX(405px);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
}
.side-dialog .side-dialog-header {
  padding: 30px 40px 20px 40px;
  box-shadow: none;
  transition: box-shadow 0.2s;
}
.side-dialog.scrolled .side-dialog-header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
}
.side-dialog .side-dialog-body {
  overflow: auto;
  flex: 1;
  padding: 50px 40px;
  padding-top: 0;
}

.side-dialog-close {
  display: block;
  color: #000;
  position: absolute;
  top: 32px;
  right: 40px;
  width: 24px;
  height: 24px;
}
.side-dialog-close .icon {
  width: 24px;
  height: 24px;
}

.dialog-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 90;
  display: none;
}
.dialog-overlay.a-fadein {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dialog-overlay.a-fadeout {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dialog-overlay.active {
  display: block;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
  min-width: 300px;
  background-color: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  border-radius: 0;
  z-index: 110;
  display: none;
}
.dialog.a-fadein {
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dialog.a-fadeout {
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dialog.active {
  display: block;
}
.dialog h3 {
  font-family: Mencken;
  font-size: 26px;
  line-height: 26px;
  font-weight: 400;
}
.dialog p {
  margin-top: 30px;
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 1.5;
}
.dialog > button {
  margin-top: 30px;
  margin-right: 10px;
}

.dialog-alert .loading {
  margin-left: auto;
  margin-right: auto;
  width: 32px;
}

.dialog-alert-v2 {
  min-width: 400px;
  padding: 0;
  transform-origin: top left;
}
.dialog-alert-v2 h3 {
  height: 55px;
  line-height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
}
.dialog-alert-v2 h3 span {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.41px;
  font-family: HelveticaNeue;
  margin-right: 10px;
}
.dialog-alert-v2 h3 a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.dialog-alert-v2 h3 a .icon {
  width: 20px;
  height: 20px;
}
.dialog-alert-v2 p.message {
  padding: 24px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 0;
}
.dialog-alert-v2 p.message:last-child {
  padding-bottom: 24px;
}
.dialog-alert-v2 .dialog-prompt-field {
  padding: 24px;
  padding-bottom: 12px;
  padding-top: 0;
}
.dialog-alert-v2 .dialog-prompt-field:first-child {
  padding-top: 24px;
}
.dialog-alert-v2 .dialog-prompt-field:last-child {
  padding-bottom: 24px;
}
.dialog-alert-v2 .dialog-prompt-form {
  padding: 24px;
  padding-bottom: 12px;
  padding-top: 0;
}
.dialog-alert-v2 .dialog-prompt-form:first-child {
  padding-top: 24px;
}
.dialog-alert-v2 .dialog-prompt-form:last-child {
  padding-bottom: 24px;
}
.dialog-alert-v2 .MuiFormLabel-root {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.176px;
  margin-top: 10px;
}
.dialog-alert-v2 .actions {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
}
.dialog-alert-v2 .actions .MuiButton-root {
  margin-left: 10px;
}
.dialog-alert-v2 .alert-link-accent {
  text-decoration: underline;
}

.dialog-header-v2 {
  height: 55px;
  min-height: 55px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dialog-header-v2 h4 {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  flex: 1;
}
.dialog-header-v2 a.tab {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dialog-header-v2 a.tab.active {
  border-bottom: 1px solid #000;
}
.dialog-header-v2 a.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  color: #000;
  margin-left: auto;
}
.dialog-header-v2 a.close .icon {
  width: 12px;
  height: 12px;
}

.dialog-footer-v2 {
  height: 55px;
  min-height: 55px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.a-fadein {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-fadein.a-fadein-active {
  opacity: 1;
}

.a-fadein.centered {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-fadein.centered.a-fadein-active {
  opacity: 1;
}

.a-fadeout {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-fadeout.a-fadeout-active {
  opacity: 0;
}

.a-zoom-in {
  opacity: 0;
  transform: scale(0.3);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-zoom-in.a-zoom-in-active {
  opacity: 1;
  transform: scale(1);
}

.a-zoom-in.centered {
  opacity: 0;
  transform-origin: top left;
  transform: scale(0.3) translate(-50%, -50%);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-zoom-in.centered.a-zoom-in-active {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.a-zoom-out {
  opacity: 1;
  transform-origin: top left;
  transform: scale(1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-zoom-out.a-zoom-out-active {
  opacity: 0;
  transform: scale(0.3);
}

.a-zoom-out.centered {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.a-zoom-out.centered.a-zoom-out-active {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%);
}

.spinning {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation-typing {
  display: inline-flex;
  justify-content: space-between;
  width: 22px;
  height: 6px;
  position: relative;
  margin-left: 5px;
}

.animation-typing-1, .animation-typing-2, .animation-typing-3 {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 3px;
  animation-name: typing;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes typing {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateY(0);
  }
}

.animation-typing-2 {
  animation-delay: 200ms;
}

.animation-typing-3 {
  animation-delay: 400ms;
}

.animation-recording {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 6px;
  position: relative;
  margin-left: 5px;
}

.animation-recording-2 {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #444;
}

.animation-recording-1 {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #444;
  animation-name: recording1;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes recording1 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.animation-recording-3 {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #444;
  animation-name: recording2;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes recording2 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
.dropzone {
  position: relative;
  cursor: pointer;
}
.dropzone input {
  transform: translate(-20%, -10px);
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
.dropzone input::-webkit-file-upload-button { /* chromes and blink button */
  cursor: pointer;
}
.dropzone p {
  cursor: pointer;
  text-align: center;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dropzone.drag-active {
  background-color: rgba(98, 147, 154, 0.3);
}
.image-viewer {
  display: none;
  position: fixed;
  width: 90%;
  height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.image-viewer img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.1);
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.image-viewer p.description {
  font-family: HelveticaNeue;
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.image-viewer .loading {
  color: #fff;
  width: 50px;
  height: 50px;
}
.image-viewer.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-viewer-close {
  position: fixed;
  display: none;
  top: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  color: #fff;
  z-index: 101;
}
.image-viewer-close .icon {
  width: 40px;
  height: 40px;
}
.image-viewer-close.active {
  display: block;
}
.pagination {
  margin-left: auto;
  margin-right: auto;
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.pagination a, .pagination span, .pagination select {
  display: inline-block;
  height: 38px;
  min-width: 50px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.pagination select {
  background-color: #000;
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 600;
}
.pagination .prev {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination .next {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination .prev, .pagination .next {
  min-width: 70px;
}
.pagination a {
  background-color: #000;
}
.pagination .prev.disabled, .pagination .next.disabled, .pagination span {
  background-color: #444;
}
.pagination a.current {
  background-color: #fff;
  color: #000;
}
.pagination select {
  margin-left: 10px;
  padding-left: 5px;
}
.pagination a {
  color: #000;
  background-color: #fff;
  border-top-color: #E8E8E8;
  border-bottom-color: #E8E8E8;
}
.pagination span {
  background-color: #fff;
  border-top-color: #E8E8E8;
  border-bottom-color: #E8E8E8;
  color: #000;
}
.pagination a.current {
  background-color: #E8E8E8;
  border-top-color: #E8E8E8;
  border-bottom-color: #E8E8E8;
}
.pagination a.prev, .pagination a.next {
  background-color: #fff;
  padding: 0 10px;
}
.pagination a.prev.disabled, .pagination a.next.disabled {
  background-color: #f5f5f5;
  color: #999;
}
.pagination select {
  background-color: #fff;
  color: #000;
}
@media screen and (max-width: 960px) {
  .pagination {
    flex-wrap: wrap;
  }
}
.quick-analytics {
  display: flex;
  justify-content: space-between;
}

.quick-analytics-block {
  width: 24%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.quick-analytics-header {
  font-family: Mencken;
  font-weight: 500;
  font-size: 30px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
}

.quick-analytics-value {
  font-family: HelveticaNeue;
  font-size: 30px;
  padding: 10px;
}
.audio-recorder-main-icon .icon {
  color: #ccc;
  width: 150px;
  height: 150px;
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.audio-recorder {
  border-radius: 50%;
  width: 400px;
  height: 400px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.audio-recorder.a-zoom-in {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.3);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.audio-recorder.a-zoom-in.a-zoom-in-active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.audio-recorder.a-zoom-out {
  display: flex;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.audio-recorder.a-zoom-out.a-zoom-out-active {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}
.audio-recorder.active {
  display: flex;
}
.audio-recorder:before, .audio-recorder:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ccc;
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.audio-recorder.recording:before, .audio-recorder.recording:after {
  opacity: 1;
  animation: animate 2s linear infinite;
}
.audio-recorder.recording:after {
  animation-delay: 1s;
}
@keyframes animate {
  0% {
    transform: scale(1.1);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.audio-recorder.recording .audio-recorder-main-icon .icon {
  color: #990000;
}
@keyframes coloring {
  0% {
    color: #ccc;
  }
  50% {
    color: #990000;
  }
  100% {
    color: #ccc;
  }
}
.audio-recorder.loading .audio-recorder-main-icon .icon {
  animation: coloring 2s linear infinite;
}

.audio-recorder-duration {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: 600;
  color: #ccc;
}

.audio-recorder-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.audio-recorder-actions .MuiButton-root {
  flex: 0.4;
  margin-left: 10px;
}
.audio-recorder-actions .MuiButton-root:first-child {
  margin-left: 0;
}
.card-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.recharts-wrapper {
  font-family: HelveticaNeue;
  font-size: 12px;
}
.page-chat {
  display: flex;
}

.chat {
  flex: 1;
  display: flex;
  width: 100%;
  min-height: 100%;
  align-content: stretch;
  align-items: stretch;
  position: relative;
}
.chat > .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat > .loading .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat .back {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000;
  margin-top: 20px;
  margin-left: 20px;
}
.chat .back .icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
  vertical-align: -2px;
}

.chat-side {
  width: 250px;
  background-color: #f4f0eb;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 960px) {
  .chat-side {
    width: 100%;
    min-width: 100%;
    flex: 1;
    display: none;
  }
  .chat-side.active {
    display: flex;
  }
}

.chat-side + .chat-side {
  width: 250px;
  background-color: #f4f0eb;
}
@media screen and (max-width: 960px) {
  .chat-side + .chat-side {
    width: 100%;
    min-width: 100%;
    flex: 1;
    display: none;
  }
  .chat-side + .chat-side.active {
    display: flex;
  }
}

.chat-side-hideable {
  width: 0 !important;
  overflow: hidden;
  transition: width 0.3s;
}
.chat-side-hideable.active {
  width: 250px !important;
}
@media screen and (max-width: 960px) {
  .chat-side-hideable.active {
    width: 100%;
    min-width: 100%;
    flex: 1;
  }
}

.chat-side-slider {
  display: block;
  position: relative;
  overflow: hidden;
}
.chat-side-slider > div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.chat-side-slider .chat-contacts {
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s;
}
.chat-side-slider .chat-contacts.active {
  transform: translateX(0);
}
.chat-side-slider .chat-consultations-wrapper {
  left: 0;
  transform: translateX(100%);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
}
.chat-side-slider .chat-consultations-wrapper.active {
  transform: translateX(0);
}

.chat-side-close {
  text-align: right;
  color: #000;
  margin-bottom: 20px;
}
.chat-side-close a {
  display: block;
  color: #000;
}
.chat-side-close .icon {
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 960px) {
  .chat-side-close {
    display: none;
    margin-bottom: 0;
  }
}

.chat-header-show-contacts {
  font-family: HelveticaNeue;
  font-size: 14px;
}
@media screen and (min-width: 960px) {
  .chat-header-show-contacts {
    display: none;
  }
}

.chat-list, .chat-contacts, .chat-consultations, .chat-attachments {
  flex: 1;
  padding: 20px;
  overflow: auto;
  position: relative;
}
.chat-list > .loading, .chat-contacts > .loading, .chat-consultations > .loading, .chat-attachments > .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat-list > .loading .icon, .chat-contacts > .loading .icon, .chat-consultations > .loading .icon, .chat-attachments > .loading .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat-contacts .chat-list-item .avatar-wrapper {
  width: 48px;
  min-width: 48px;
  margin-right: 10px;
}
.chat-contacts .chat-list-item .avatar {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  line-height: 48px;
}
.chat-contacts .chat-list-item .name {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000;
}
.chat-contacts .chat-list-item .chat-contact-activity-recording {
  display: block;
}
.chat-contacts .chat-list-item .chat-contact-activity-typing {
  display: block;
}

.chat-list-item {
  border-radius: 12px;
  padding: 7px 7px;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.chat-list-item .badge-unread {
  position: absolute;
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  background-color: #000;
}
.chat-list-item .badge-online {
  position: absolute;
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  background-color: #229922;
}
.chat-list-item div {
  overflow: hidden;
}
.chat-list-item .last-message {
  display: flex;
}
.chat-list-item .avatar {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  margin-right: 4px;
}
.chat-list-item .chat-list-icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  padding: 12px;
  margin-right: 10px;
}
.chat-list-item .icon {
  width: 24px;
  height: 24px;
}
.chat-list-item .chat-list-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chat-list-item h3 {
  width: 100%;
  margin-top: 4px;
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
}
.chat-list-item h3 .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-list-item h3 .time {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.chat-list-item p {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.chat-list-item > a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.chat-list-general {
  padding-right: 12px;
}

.chat-list-look {
  padding: 10px 0;
  flex-direction: column;
}
.chat-list-look h3 {
  font-family: Mencken;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  padding-left: 12px;
  padding-right: 12px;
}
.chat-list-look h3 a {
  color: #000;
}
.chat-list-look p {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat-list-look p a {
  color: #000;
}
.chat-list-look h4 {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chat-list-look-item {
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
  padding: 0 12px 0 12px;
}
.chat-list-look-item .tooltip {
  position: absolute;
  right: 8px;
  top: 2px;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-family: HelveticaNeue;
  font-size: 12px;
  line-height: 12px;
  z-index: 40;
  white-space: nowrap;
  border-radius: 5px;
  pointer-events: none;
}
.chat-list-look-item:hover .chat-list-look-images {
  border-color: #000;
}

.chat-list-look-images {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 8px;
  transition: border-color 0.3s;
}
.chat-list-look-images div {
  margin-right: 5px;
  width: 50px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;
  border-radius: 8px;
}
.chat-list-look-images div:last-child {
  margin-right: 0;
}
.chat-list-look-images div:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.chat-header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.chat-header .user-handle .avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.chat-header .user-handle .name {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}
.chat-header a {
  color: #000;
}
.chat-header a.close {
  width: 20px;
  height: 20px;
}
.chat-header a.close .icon {
  width: 20px;
  height: 20px;
}
.chat-header a.search {
  width: 20px;
  height: 20px;
  color: #ccc;
  transition: color 0.3s;
}
.chat-header a.search:hover {
  color: #000;
}
.chat-header a.search .icon {
  width: 20px;
  height: 20px;
}
.chat-header .MuiTextField-root {
  margin-left: auto;
  margin-right: 10px;
}
.chat-header .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}
.chat-header .MuiTextField-root fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 62px;
}
.chat-header .MuiTextField-root a.search {
  width: 16px;
  height: 16px;
}
.chat-header .MuiTextField-root a.search .icon {
  width: 16px;
  height: 16px;
}

.chat-contacts .chat-list-item {
  background-color: rgba(0, 0, 0, 0.04);
  border-width: 0;
}
.chat-contacts .chat-list-item.active {
  border: 1px solid #E8E8E8;
  background-color: #fff;
}

.chat-attachments {
  border-left: 1px solid #e5e5e5;
}
.chat-attachments .chat-list-item {
  border: 1px solid #E8E8E8;
  background-color: #fff;
}
.chat-attachments .chat-list-item.active {
  background-color: rgba(0, 0, 0, 0.04);
  border-width: 0;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.chat-messages > .loading-previous div {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.chat-messages > .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat-messages > .loading .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat-messages > .sending {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.chat-messages .empty {
  text-align: center;
  padding: 50px;
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.chat-entry {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 8px;
  position: relative;
}
.chat-entry .avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.chat-entry.mine .avatar {
  margin-right: 0;
  margin-left: 10px;
}
.chat-entry .chat-entry-info {
  display: flex;
  height: 30px;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .chat-entry .chat-entry-info {
    width: 100%;
  }
}
.chat-entry .chat-entry-message-bubble {
  padding: 7px;
  border-radius: 14px;
}
.chat-entry .chat-entry-message-bubble audio {
  height: 26px;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-message-bubble {
    max-width: 50%;
  }
}
.chat-entry p.chat-entry-message {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.chat-entry p.chat-entry-message a {
  color: #000;
  text-decoration: underline;
}
.chat-entry .chat-entry-reactions {
  font-size: 20px;
  position: absolute;
}
.chat-entry div.chat-entry-sending {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.chat-entry div.chat-entry-status {
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 5px;
}
.chat-entry div.chat-entry-status .icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  color: #ccc;
}
.chat-entry div.chat-entry-status.chat-entry-read {
  margin-right: 10px;
}
.chat-entry div.chat-entry-status.chat-entry-read .icon {
  color: #062a3c;
}
.chat-entry div.chat-entry-status.chat-entry-read .icon:last-child {
  left: 5px;
}
.chat-entry div.chat-entry-status.chat-entry-pending {
  display: none;
}
.chat-entry p.chat-entry-action {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.chat-entry .look-card {
  width: 200px;
}
.chat-entry .look-card .icon-heart, .chat-entry .look-card .icon-like, .chat-entry .look-card .icon-dislike, .chat-entry .look-card .icon-refresh, .chat-entry .look-card .icon-shop {
  width: 18px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.chat-entry .look-card .icon-heart {
  color: #eeb7be;
}
.chat-entry .look-card .icon-shop {
  color: #eeb7be;
}
.chat-entry .look-card .icon-dislike {
  color: #888;
}
.chat-entry .moodboard-card {
  width: 200px;
}
.chat-entry .product-card {
  width: 150px;
}
.chat-entry .product-card .icon-heart, .chat-entry .product-card .icon-like, .chat-entry .product-card .icon-dislike, .chat-entry .product-card .icon-refresh, .chat-entry .product-card .icon-shop {
  width: 18px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.chat-entry .product-card .icon-heart {
  color: #eeb7be;
}
.chat-entry .product-card .icon-shop {
  color: #eeb7be;
}
.chat-entry .product-card .icon-dislike {
  color: #888;
}
.chat-entry .product-card .product-card-footer {
  position: relative;
}
.chat-entry .product-card .product-card-footer div.flex-break-h {
  display: block;
}
.chat-entry .product-card .product-card-footer p.name {
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat-entry .chat-entry-icons {
  margin-right: 10px;
  transition: opacity 0.3s;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-icons {
    opacity: 0;
  }
}
.chat-entry .chat-entry-icons a {
  display: inline-block;
}
.chat-entry .chat-entry-icons .icon {
  width: 16px;
  height: 16px;
  color: #000;
}
.chat-entry .chat-entry-images-container {
  max-width: 400px;
  overflow: auto;
}
.chat-entry .chat-entry-images {
  display: flex;
}
.chat-entry .chat-entry-image {
  height: 150px;
  cursor: pointer;
}
.chat-entry .chat-entry-image img {
  height: 150px;
  width: auto;
}
.chat-entry .chat-entry-catalogue-container {
  max-width: 250px;
  overflow: auto;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-catalogue-container {
    max-width: 400px;
  }
}
.chat-entry .chat-entry-catalogue {
  display: flex;
  flex-direction: row;
}
.chat-entry .chat-entry-catalogue .product-card {
  min-width: 100px;
}
.chat-entry .chat-entry-date {
  font-family: HelveticaNeue;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  transition: opacity 0.3s;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-date {
    opacity: 0;
  }
}
.chat-entry .chat-entry-reply-action {
  transition: opacity 0.3s;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-reply-action {
    opacity: 0;
  }
}
.chat-entry .chat-entry-reply-action a {
  display: inline-block;
  color: #666;
}
.chat-entry .chat-entry-reply-action .icon {
  width: 14px;
  height: 14px;
}
.chat-entry .chat-entry-emoji-picker {
  position: absolute;
  bottom: 0;
}
.chat-entry .chat-entry-emoji-action {
  transition: opacity 0.3s;
}
@media screen and (min-width: 960px) {
  .chat-entry .chat-entry-emoji-action {
    opacity: 0;
  }
}
.chat-entry .chat-entry-emoji-action a {
  display: inline-block;
  color: #666;
}
.chat-entry .chat-entry-emoji-action .icon {
  color: #666;
  width: 14px;
  height: 14px;
}
.chat-entry .chat-entry-reply {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  opacity: 0.6;
  cursor: pointer;
}
.chat-entry .chat-entry-reply .avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
}
.chat-entry .chat-entry-reply p.chat-entry-message {
  font-size: 12px;
  max-width: 100%;
}
.chat-entry.other .chat-entry-message-bubble {
  position: relative;
  border: 1px solid #f0f0f0;
}
.chat-entry.other .chat-entry-message-bubble audio {
  background-color: #fff;
}
.chat-entry.other .chat-entry-reactions {
  left: -10px;
  bottom: -15px;
}
.chat-entry.other div.chat-entry-date {
  margin-left: 10px;
}
.chat-entry.other .chat-entry-reply-action {
  margin-left: 10px;
}
.chat-entry.other .chat-entry-emoji-action {
  margin-left: 10px;
}
.chat-entry.other .chat-entry-reply .avatar {
  margin-left: 10px;
}
.chat-entry.mine {
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.chat-entry.mine .chat-entry-info {
  justify-content: flex-end;
}
.chat-entry.mine div.chat-entry-date {
  margin-right: 10px;
}
.chat-entry.mine .chat-entry-reactions {
  right: -10px;
  bottom: -15px;
}
.chat-entry.mine .chat-entry-message-bubble {
  position: relative;
  background-color: #F0F0F0;
}
.chat-entry.mine .chat-entry-message-bubble audio {
  background-color: #f0f0f0;
}
.chat-entry.mine .chat-entry-reply-action {
  margin-right: 10px;
}
.chat-entry.mine .chat-entry-emoji-action {
  margin-right: 10px;
}
.chat-entry:hover .chat-entry-icons {
  opacity: 1;
}
.chat-entry:hover .chat-entry-date {
  opacity: 1;
}
.chat-entry:hover .chat-entry-reply-action {
  opacity: 1;
}
.chat-entry:hover .chat-entry-emoji-action {
  opacity: 1;
}
.chat-entry .chat-entry-reply .chat-entry-message-bubble {
  max-width: 100%;
  border: 0;
  background: #d0d0d0;
}

.chat-messages-current-activity {
  display: flex;
  align-items: center;
}
.chat-messages-current-activity .avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.chat-messages-current-activity .chat-messages-activity-typing, .chat-messages-current-activity .chat-messages-activity-recording {
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #666;
}

.chat-message-form {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.chat-message-form .dropzone {
  overflow: hidden;
  width: 24px;
}
@media screen and (max-width: 960px) {
  .chat-message-form {
    padding-bottom: 90px;
  }
  .chat-message-form.focused {
    padding-bottom: 20px;
  }
}

.chat-message-form-dropzone.drag-active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: 5px solid #000;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.1);
}

.chat-form-attachment {
  width: 100px;
  position: relative;
}

.chat-form-attachments {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  padding-top: 5px;
}

.chat-form-attachments-list {
  display: flex;
  gap: 10px;
}

.chat-form-attachment-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  color: #000;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  z-index: 1;
}
.chat-form-attachment-remove .icon {
  width: 12px;
  height: 12px;
}

.chat-form-attachment-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1;
  width: 100%;
}

.chat-form-reply {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.chat-form-reply .avatar {
  margin-right: 10px;
  opacity: 0.6;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.chat-form-reply p.chat-entry-message {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 7px 12px;
  border-radius: 14px;
  max-width: 50%;
  color: #666;
}
.chat-form-reply .chat-form-reply-spacer {
  margin-left: auto;
}
.chat-form-reply .chat-form-reply-remove {
  color: #000;
}
.chat-form-reply .chat-form-reply-remove .icon {
  width: 20px;
  height: 20px;
}
.chat-form-reply .product-card {
  width: 100px;
  margin-right: 10px;
  opacity: 0.6;
}
.chat-form-reply .look-card {
  width: 140px;
  margin-right: 10px;
  opacity: 0.6;
}
.chat-form-reply .chat-form-reply-images {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  opacity: 0.6;
}
.chat-form-reply .chat-form-reply-images .chat-entry-image {
  height: 120px;
}
.chat-form-reply .chat-form-reply-images .chat-entry-image img {
  height: 120px;
  width: auto;
}

.chat-message-field-container {
  display: flex;
  align-items: center;
}

.chat-message-field fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 62px;
}
.chat-message-field .icon {
  width: 24px;
  height: 24px;
  color: #000;
  margin-top: 4px;
}
.chat-message-field .icon-loading {
  margin-top: 0;
}
.chat-message-field audio {
  height: 30px;
  margin-left: 10px;
}
.chat-message-field .MuiInputAdornment-root {
  margin-right: 10px;
  cursor: pointer;
}
.chat-message-field .MuiInputAdornment-root a {
  display: block;
  margin-left: 10px;
}
.chat-message-field .MuiInputAdornment-root a.chat-message-field-sidebar {
  margin-left: 15px;
}
.chat-message-field .MuiInputAdornment-root a.chat-message-field-catalogue {
  margin-right: 15px;
}
.chat-message-field .MuiInputAdornment-root a.chat-message-field-moodboard {
  margin-right: 5px;
  margin-top: 2px;
}
.chat-message-field .MuiInputAdornment-root .icon-bookmark {
  width: 18px;
  height: 18px;
}
.chat-message-field .MuiInputAdornment-root .icon-shopping {
  width: 18px;
  height: 18px;
}
.chat-message-field .MuiInputAdornment-root .icon-attach {
  width: 18px;
  height: 18px;
}

a.chat-message-send {
  display: block;
  margin-left: 10px;
}
a.chat-message-send .icon {
  width: 18px;
  height: 18px;
  color: #000;
}

a.chat-message-send-disabled .icon {
  color: #ccc;
}

.chat-message-field-mic {
  display: block;
  margin-left: 10px;
}
.chat-message-field-mic .icon-mic {
  width: 18px;
  height: 18px;
  color: #000;
}

.chat-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 130px;
  width: auto;
  height: auto;
  margin: 0;
  transform: none;
  padding: 0;
}
.chat-dialog.active {
  transform: none;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
@media screen and (min-width: 960px) {
  .chat-dialog.active {
    /*top: 80px;
    left: $padding;
    right: $padding;
    bottom: $padding;*/
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
    flex-direction: row;
  }
}
.chat-dialog.a-zoom-in {
  opacity: 0;
  transform: translate(-50%, -50%), scale(0.3);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.chat-dialog.a-zoom-in.a-zoom-in-active {
  opacity: 1;
  transform: translate(-50%, -50%), scale(1);
}
.chat-dialog.a-zoom-out {
  display: flex;
  opacity: 1;
  transform: translate(-50%, -50%), scale(1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.chat-dialog.a-zoom-out.a-zoom-out-active {
  transform: translate(-50%, -50%), scale(1);
  opacity: 0;
}
.chat-dialog p {
  margin-top: 0;
}

.chat-contacts-invite {
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 18px;
}
.chat-contacts-invite a {
  color: #000;
  text-decoration: underline;
}
.emoji-picker {
  /*position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  min-width: 400px;
  max-width: 480px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: height 0.3s;
  z-index: 10;
}
.emoji-picker.expanded {
  height: 400px;
}

.emoji-picker-emojis {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex: 1;
  align-content: flex-start;
}

.emoji-picker-emoji {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.emoji-picker-emoji:hover {
  background-color: #f0f0f0;
}

.emoji-picker-groups {
  height: 40px;
  border-bottom: 1px solid #ccc;
  display: flex;
}

.emoji-picker-group {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 28px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center; /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.emoji-picker-group:hover {
  background-color: #f0f0f0;
}
.emoji-picker-group.current {
  filter: none;
}

.emoji-picker-recents {
  height: 40px;
  display: flex;
}
.emoji-picker.expanded .emoji-picker-recents {
  border-bottom: 1px solid #ccc;
}

.emoji-recents-expand {
  width: 30px;
  height: 40px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-recents-expand .icon {
  width: 20px;
  height: 20px;
  color: #444;
  transition: transform 0.3s;
}
.emoji-recents-expand:last-child {
  margin-right: 5px;
}

.emoji-picker-delete {
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.emoji-picker-delete:hover {
  background-color: #f0f0f0;
}
.emoji-picker-delete .icon {
  width: 16px;
  height: 16px;
  color: #990000;
  transition: transform 0.3s;
}

.emoji-recents-close {
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-recents-close .icon {
  width: 14px;
  height: 14px;
  color: #444;
  transition: transform 0.3s;
}
.emoji-recents-close:last-child {
  margin-right: 5px;
}

.emoji-picker.expanded .emoji-recents-expand .icon {
  transform: rotate(-180deg);
}
.help-icon {
  display: inline-block;
}
.moodboard-card {
  cursor: pointer;
  position: relative;
}
.moodboard-card .moodboard-card-images {
  border: 1px solid #E8E8E8;
  border-radius: 14px;
  height: 0;
  padding-bottom: 110%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}
.moodboard-card .moodboard-card-images div {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-1 div {
  width: 100%;
  height: 100%;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-2 div {
  width: 50%;
  height: 100%;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-2 div:first-child {
  left: 0;
  border-right: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-2 div:last-child {
  right: 0;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-3 div:nth-child(1) {
  width: 50%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-right: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-3 div:nth-child(2) {
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
  border-bottom: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-3 div:nth-child(3) {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
}
.moodboard-card .moodboard-card-images .moodboard-card-images-many div {
  display: none;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-4 div:nth-child(1), .moodboard-card .moodboard-card-images.moodboard-card-images-many div:nth-child(1) {
  display: block;
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-4 div:nth-child(2), .moodboard-card .moodboard-card-images.moodboard-card-images-many div:nth-child(2) {
  display: block;
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
  border-bottom: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-4 div:nth-child(3), .moodboard-card .moodboard-card-images.moodboard-card-images-many div:nth-child(3) {
  display: block;
  width: 50%;
  height: 50%;
  bottom: 0;
  left: 0;
  border-right: 1px solid #E8E8E8;
}
.moodboard-card .moodboard-card-images.moodboard-card-images-4 div:nth-child(4), .moodboard-card .moodboard-card-images.moodboard-card-images-many div:nth-child(4) {
  display: block;
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
}
.moodboard-card .card-footer {
  margin-top: 0;
  text-align: center;
}
.moodboard-card .card-footer .name {
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.41px;
  color: #000;
  margin-top: 10px;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.moodboard-card .card-footer .count {
  font-family: HelveticaNeue;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 2px;
  margin-bottom: 10px;
}
.moodboard-card a.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  right: 70px;
  color: #000;
  border-radius: 13px;
  background-color: #fff;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.moodboard-card a.edit .icon {
  width: 12px;
  height: 12px;
}
.moodboard-card a.send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  right: 40px;
  color: #000;
  border-radius: 13px;
  background-color: #fff;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.moodboard-card a.send .icon {
  width: 12px;
  height: 12px;
}
.moodboard-card a.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  border-radius: 13px;
  background-color: #fff;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.moodboard-card a.delete .icon {
  width: 12px;
  height: 12px;
}
.moodboard-card .moodboard-card-public {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  left: 10px;
  color: #000;
  border-radius: 13px;
  background-color: #fff;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.moodboard-card .moodboard-card-public .icon {
  width: 12px;
  height: 12px;
}
.moodboard-card .moodboard-card-loading {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 14px;
}
.moodboard-card:before {
  content: "";
  pointer-events: none;
  position: absolute;
  opacity: 0;
  height: 0;
  padding-bottom: 110%;
  width: 100%;
  top: 1px;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 14px;
}
.moodboard-card.not-clickable {
  cursor: default;
}
.moodboard-card.moodboard-card-deleted {
  cursor: default;
}
.moodboard-card:hover {
  /*a.edit {
      opacity: 1;
  }
  a.send {
      opacity: 1;
  }
  a.delete {
      opacity: 1;
  }*/
}
.moodboard-card:hover:before {
  opacity: 1;
}
.moodboard-card:hover.not-clickable:before {
  opacity: 0;
}
.moodboard-card:hover.moodboard-card-deleted:before {
  opacity: 0;
}

.moodboard-card-deleted-text {
  flex: 1;
  height: 100%;
  font-family: HelveticaNeue;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-wrap: balance;
  color: #444;
}
.notifications-dialog {
  position: absolute;
  transform: none;
  font-size: 12px;
  max-height: 400px;
  max-width: 400px;
  overflow: auto;
  white-space: normal;
  margin-top: 1px;
  padding-top: 20px;
}
.notifications-dialog .loading {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 10px;
  width: 30px;
}
.notifications-dialog p {
  font-size: 12px;
  font-family: HelveticaNeue;
  letter-spacing: 0;
  margin-top: 0;
}
.notifications-dialog div {
  width: 100%;
}

.notification {
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
  font-family: HelveticaNeue;
  display: flex;
  align-items: center;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0;
}
.notification .avatar {
  display: block;
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
}

.notification-read, .notification-unread {
  display: block;
  width: 10px;
  height: 10px;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
}

.notification-read {
  border: 1px solid #ccc;
}

.notification-unread {
  background-color: #000;
}

.notification-text {
  color: #000;
  display: block;
}

.notification-date {
  font-size: 10px;
  color: #999;
  font-family: HelveticaNeue;
}

.notification-group {
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  font-family: HelveticaNeue;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 14px;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  color: #999;
}

.notification-action {
  min-width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  color: #999;
}
.notification-action .icon {
  width: 10px;
  height: 10px;
  color: #000;
}

.notification-subtext {
  font-size: 9px;
  color: #000;
  font-weight: 300;
  font-family: HelveticaNeue;
  white-space: normal;
  display: block;
}
.public-selector-date.tag-selector-v2 {
  position: relative;
}
.public-selector-date.tag-selector-v2 .date-range-selector {
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
}

.tag-selector-v2 .tag-selector-body.tag-selector-search {
  min-height: auto;
}
.copy-field input {
  font-family: HelveticaNeue;
  font-size: 12px !important;
}
.copy-field .MuiInputAdornment-positionStart {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  max-height: none;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1.5s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.copy-field .MuiInputAdornment-positionStart.animated {
  opacity: 0;
  transform: translateY(-100%);
}
.copy-field .MuiInputAdornment-positionEnd {
  font-size: 12px;
}
.copy-field .MuiInputAdornment-positionEnd .icon {
  margin-right: 5px;
  vertical-align: top;
}
.copy-field .MuiInputAdornment-positionEnd a {
  font-family: HelveticaNeue;
  color: #444;
}
.copy-field .MuiInputAdornment-root {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.copy-field .MuiInputAdornment-root a {
  font-family: HelveticaNeue;
  display: inline-block;
  height: 20px;
}
.copy-field .icon {
  width: 20px;
  height: 20px;
}
.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 6px;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.form-field-label {
  font-size: 12px;
  font-family: Nobel;
  font-weight: 300;
  letter-spacing: 0.6px;
  margin-bottom: 5px;
}

.form-field-error {
  font-family: HelveticaNeue;
  font-weight: 400;
  font-size: 11px !important;
  line-height: 11px !important;
  color: #932c2c;
  margin-top: 5px !important;
  margin-left: 5px;
}
.catalogue-dialog {
  border-radius: 10px;
  padding: 20px;
  min-width: 600px;
  min-height: 400px;
  max-height: 600px;
  border: 6px solid #101010;
  flex-direction: column;
  justify-content: space-between;
}
.catalogue-dialog.active {
  display: flex;
}
.catalogue-dialog .catalogue-products {
  flex: 1;
}
.catalogue-dialog .catalogue-products .loading {
  margin-top: 50px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.catalogue-dialog .catalogue-products .loading .icon {
  width: 40px;
  height: 40px;
}
.catalogue-dialog .pagination {
  margin-top: 0;
  margin-bottom: 10px;
}
.catalogue-dialog .MuiButton-root {
  margin-top: 0;
  margin-right: 0;
}
.catalogue-dialog .infinite-scrolling-container {
  flex: 1;
  overflow: auto;
}
.catalogue-dialog .infinite-scrolling-more {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.catalogue-dialog .infinite-scrolling-more .icon {
  width: 40px;
  height: 40px;
}

.catalogue-dialog-header {
  flex-direction: row;
  display: flex;
}
.catalogue-dialog-header .MuiTextField-root {
  margin-right: 10px;
  flex: 1;
}
.catalogue-dialog-header .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}

.catalogue-dialog-filters {
  margin-top: 0;
}
.catalogue-dialog-filters .MuiInput-root {
  margin-top: 0;
  margin-right: 5px;
}
.catalogue-dialog-filters .MuiInputBase-root fieldset {
  border: 0 !important;
}
.catalogue-dialog-filters .MuiSelect-select {
  font-size: 12px;
  white-space: normal;
  padding-top: 8px;
  padding-bottom: 8px;
}
.catalogue-products {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  margin-left: -5px;
  margin-right: -5px;
}
.catalogue-products .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.catalogue-products .catalogue-products-item {
  width: 20%;
  padding: 0 5px 10px 5px;
  opacity: 1;
  transition: opacity 0.3s;
}
.catalogue-products.catalogue-products-loading .catalogue-products-item {
  opacity: 0.5;
}

.product-card.catalogue-product-card .product-card-action-left,
.product-card.catalogue-product-card .product-card-action-right {
  bottom: 80px;
}
.product-card.catalogue-product-card .product-card-image {
  background-size: contain;
}
.product-card.catalogue-product-card .product-card-footer {
  position: relative;
}
.product-card.catalogue-product-card .product-card-footer p {
  line-height: 1.5;
  font-size: 14px;
  font-family: HelveticaNeue;
  margin-top: 4px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.product-card.catalogue-product-card .product-card-footer p b {
  font-weight: 700;
}
.product-card.catalogue-product-card .product-card-footer p.retailer {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}
.product-card.catalogue-product-card .product-card-footer p.brand {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #000;
  flex-basis: 100%;
}
.product-card.catalogue-product-card .product-card-footer p.name {
  display: block;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 0;
}
.product-card.catalogue-product-card .product-card-footer p.price {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #000;
  text-align: center;
}
.product-card.catalogue-product-card .product-card-footer p.price span + span {
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: line-through;
}
.page-profile {
  margin-top: 30px;
}
.page-profile .profile-card {
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(141, 141, 141, 0.3);
  position: relative;
  margin-bottom: 30px;
  display: flex;
}
@media screen and (min-width: 600px) {
  .page-profile .profile-card {
    flex: 1;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 960px) {
  .page-profile .profile-card {
    min-height: 0;
    flex: 1;
    aspect-ratio: 0.5;
    max-height: 600px;
  }
}
.page-profile .profile-card > div {
  padding: 30px;
}
.page-profile .profile-card > div > label {
  display: block;
  font-family: HelveticaNeue;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}
@media screen and (min-width: 600px) {
  .page-profile .profile-card > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.page-profile .profile-card .footer-actions {
  margin-top: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.page-profile .profile-card .footer-button {
  margin-top: 30px;
}
@media screen and (min-width: 600px) {
  .page-profile .profile-card .footer-button {
    margin-top: auto;
  }
}
@media screen and (min-width: 600px) {
  .page-profile .cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media screen and (min-width: 960px) {
  .page-profile .cards {
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
  }
}
.page-profile .cards.plain-user .card-bio {
  box-shadow: none;
}
.page-profile .cards.plain-user .card-user-password {
  box-shadow: none;
}
.page-profile .card-bio p {
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (min-width: 1280px) {
  .page-profile .card-bio p {
    -webkit-line-clamp: 20;
  }
}
.page-profile .profile-card-password-btn {
  margin-top: 30px;
}
.page-profile .card-analytics label {
  margin-bottom: 10px;
  margin-top: 30px;
}
.page-profile .card-analytics label:first-child {
  margin-top: 0;
}
.page-profile .card-analytics .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page-profile .card-analytics-stats {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.page-profile .card-analytics-stats p {
  font-family: HelveticaNeue;
  font-size: 16px;
}
.page-profile .card-analytics-stats p:last-child {
  margin-left: auto;
  font-weight: bold;
}
.page-profile .card-analytics-stats .icon {
  width: 30px;
  height: 30px;
  color: #000;
  margin-right: 10px;
}
.page-profile .card-analytics-periods {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.page-profile .card-analytics-periods a {
  display: block;
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #000;
  text-decoration: underline;
}
.page-profile .card-analytics-periods a.active {
  font-weight: bold;
}
.page-profile .card-analytics-total {
  display: flex;
  align-items: baseline;
}
.page-profile .card-analytics-total p:first-child {
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  font-family: HelveticaNeue;
}
.page-profile .card-analytics-total p:last-child {
  margin-left: 10px;
  font-size: 18px;
  line-height: 18px;
  font-family: HelveticaNeue;
}

.card-user-details h3 {
  font-family: Mencken;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  font-style: italic;
  margin-top: 40px;
}
.card-user-details .email {
  font-family: HelveticaNeue;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 400;
}
.card-user-details .email-verified {
  display: inline-block;
  border-radius: 50%;
  background-color: #62939a;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  vertical-align: bottom;
  margin-left: 7px;
}
.card-user-details .email-verified .icon {
  width: 10px;
  height: 10px;
  color: #fff;
  vertical-align: center;
}
.card-user-details .email-not-verified {
  font-family: HelveticaNeue;
  font-size: 12px;
  margin-top: 10px;
  color: #d7606f;
}
.card-user-details .email-not-verified a {
  margin-left: 7px;
  display: inline-block;
  text-decoration: underline;
  color: #000;
}
.card-user-details .email-not-verified svg {
  margin-left: 7px;
  display: inline-block;
  color: #000;
  vertical-align: bottom;
}
.card-user-details .email-not-verified span {
  margin-left: 7px;
  display: inline-block;
  color: #000;
}
.card-user-details .phone {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}
.card-user-details .phone label {
  display: block;
  font-weight: 500;
}
.card-user-details .signedup {
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
}
.card-user-details .signedup span {
  font-weight: 400;
}

.dialog-user-details .MuiTextField-root {
  margin-top: 17px;
}
.dialog-user-details textarea {
  margin-top: 17px;
  width: 100%;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 14px;
}
.dialog-user-details .dz-wrapper {
  border: 1px dotted #062a3c;
}
.dialog-user-details .resend-code {
  font-family: HelveticaNeue;
  font-size: 12px;
  margin-top: 10px;
}
.dialog-user-details .resend-code a {
  color: #000;
  text-decoration: underline;
}
.dialog-user-details .dropzone {
  padding-bottom: 100%;
  width: 100%;
  overflow: hidden;
}
.dialog-user-details .dropzone img {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: move;
}
.dialog-user-details .dropzone p {
  font-family: HelveticaNeue;
  font-size: 14px;
  text-align: center;
}
.dialog-user-details .dropzone img.vertical {
  width: 100%;
}
.dialog-user-details .dropzone img.horizontal {
  height: 100%;
}
.dialog-user-details .dropzone img.square {
  width: 100%;
}
.dialog-user-details .MuiFormControlLabel-root {
  display: flex;
  margin-top: 10px;
}
.dialog-user-details button {
  margin-top: 50px;
  margin-right: 10px;
}

.card-user-password p {
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: HelveticaNeue;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.card-bio p {
  margin-top: 40px;
  font-family: HelveticaNeue;
  font-size: 13px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 960px) {
  .card-avatar {
    min-height: 0;
  }
}
.card-avatar .card-avatar-image {
  border: 1px dotted #062a3c;
  margin-top: 40px;
  max-width: 150px;
}
@media screen and (min-width: 960px) {
  .card-avatar .card-avatar-image {
    max-width: 250px;
  }
}
.card-avatar .card-avatar-image > div {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.card-avatar .card-avatar-image img {
  display: block;
  width: 100%;
  position: absolute;
}
.card-avatar .card-avatar-image a {
  position: absolute;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 6px;
  top: 5px;
  right: 5px;
}
.card-avatar .card-avatar-image a .icon {
  width: 14px;
  height: 14px;
}
.card-avatar .card-avatar-image .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-avatar .card-avatar-image .avatar {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  width: 114px;
  height: 114px;
  min-width: 114px;
  min-height: 114px;
  line-height: 114px;
}
.page.page-signin, .page.page-signup {
  padding-left: 12%;
  padding-right: 12%;
}
@media screen and (min-width: 600px) {
  .page.page-signin, .page.page-signup {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 960px) {
  .page.page-signin, .page.page-signup {
    padding-bottom: 70px;
  }
}

.page-signin, .page-signup {
  max-width: 380px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
}
.page-signin h1, .page-signup h1 {
  font-size: 32px;
  line-height: 32px;
  font-family: Mencken;
  font-weight: 500;
  margin-bottom: 20px;
}
@media screen and (min-width: 600px) {
  .page-signin h1, .page-signup h1 {
    margin-top: 20px;
  }
}
.page-signin button, .page-signup button {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 40px;
  width: 100%;
}
.page-signin button .MuiButton-label, .page-signup button .MuiButton-label {
  line-height: 56px;
}
.page-signin [data-test=authenticator-error], .page-signup [data-test=authenticator-error] {
  display: none;
}
.page-signin .MuiTextField-root, .page-signup .MuiTextField-root {
  margin-top: 20px;
}
.page-signin .resend-link, .page-signup .resend-link {
  padding-left: 4px;
  line-height: 20px;
  margin-top: 20px;
}
.page-signin .resend-link a, .page-signup .resend-link a {
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #fff;
  text-decoration: underline;
}
.page-signin .resend-link .loading, .page-signup .resend-link .loading {
  width: 20px;
  height: 20px;
}
.page-signin .forgot-link, .page-signup .forgot-link {
  padding-left: 4px;
  line-height: 20px;
}
.page-signin .forgot-link a, .page-signup .forgot-link a {
  font-size: 13px;
  font-family: HelveticaNeue;
  color: #fff;
}
.page-signin .secondary-action, .page-signup .secondary-action {
  margin-top: 20px;
  font-size: 16px;
  font-family: HelveticaNeue;
  color: #fff;
}
.page-signin .secondary-action a, .page-signup .secondary-action a {
  font-size: 16px;
  font-family: HelveticaNeue;
  color: #fff;
  text-decoration: underline;
  margin-right: 10px;
}
.page-signin .page-loading, .page-signup .page-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 10;
}
.page-signin .page-loading .icon, .page-signup .page-loading .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-forgot-password h1 {
  font-size: 30px;
}

.page-signup .MuiFormControlLabel-root {
  margin-top: 20px;
}
@media screen and (min-width: 600px) {
  .page-signup .MuiFormControlLabel-root {
    padding-left: 0;
    padding-right: 0;
  }
}
.page-signup .MuiFormControlLabel-root a {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}
.page-signup .agree-error {
  margin-top: 5px;
  margin-left: 3px;
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: 400;
  color: #efa0b9;
}
.page-signup .note {
  margin-top: 10px;
  margin-left: 30px;
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
}
.page-signup .note a {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}
.page-signup .field-note {
  padding-left: 4px;
  font-size: 13px;
  font-family: HelveticaNeue;
  color: #fff;
}
.page-signup h1 {
  margin-bottom: 20px;
}
.dialog.tag-dialog {
  padding: 10px 30px;
  max-width: 400px;
}
.dialog.tag-dialog .dialog-header-v2 {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 10px;
}
.dialog.tag-dialog .dialog-header-v2 h4 {
  padding-left: 0;
}
.dialog.tag-dialog .dialog-footer-v2 {
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-start;
}
.dialog.tag-dialog .dialog-footer-v2 .MuiButton-root:last-child {
  margin-left: auto;
}
.dialog.tag-dialog .tag-link {
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #000;
  text-decoration: underline;
  margin-top: 10px;
  display: inline-block;
}
.tag {
  display: inline-block;
  font-family: HelveticaNeue;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 6px;
  background-color: #f4f0eb;
  padding: 0 10px;
  margin-top: 7px;
  margin-right: 7px;
}

.tag-list label {
  font-family: HelveticaNeue;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  display: block;
}
.tag-list .MuiChip-root .icon {
  pointer-events: all;
}
.tag-list.tag-list-dense label {
  margin-bottom: 7px;
}
.tag-list.tag-list-dense .MuiChip-root {
  margin-right: 7px;
  margin-bottom: 7px;
}
.tag-list.tag-list-normal label {
  margin-bottom: 15px;
}
.tag-list.tag-list-normal .MuiChip-root {
  margin-right: 15px;
  margin-bottom: 15px;
}
.tag-selector .MuiChip-root .icon {
  pointer-events: all;
}
.tag-selector .MuiInputAdornment-positionStart {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}
.tag-selector .MuiAutocomplete-endAdornment {
  display: flex;
  height: 28px;
}
.tag-selector .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.tag-selector .MuiAutocomplete-clearIndicator {
  margin-right: 3px;
}
.tag-selector .MuiAutocomplete-clearIndicator:hover {
  background-color: transparent;
}
.tag-selector .MuiAutocomplete-clearIndicator .icon-close {
  color: #000 !important;
  width: 10px;
  height: 10px;
}
.tag-selector .MuiAutocomplete-popupIndicator {
  margin-right: 2px;
}
.tag-selector .MuiAutocomplete-popupIndicator:hover {
  background-color: transparent;
}
.tag-selector .loading {
  width: 14px;
  height: 14px;
}
.tag-selector .icon-loading {
  color: #000 !important;
  width: 14px !important;
  height: 14px !important;
}
.tag-selector .tag-selector-num-selected {
  display: block;
  border-radius: 50%;
  background-color: #ccc;
  font-size: 11px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #000;
}
.tag-selector-v2 {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.tag-selector-v2 .tag-selector-header {
  height: 40px;
  position: relative;
  cursor: pointer;
}
.tag-selector-v2 .tag-selector-header label {
  cursor: pointer;
  display: block;
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
}
.tag-selector-v2 .tag-selector-header span {
  display: block;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.4);
}
.tag-selector-v2 .tag-selector-header .tag-selector-clear {
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translate(0, -50%);
  transition: opacity 0.3s, transform 0.3s, top 0.3s;
  color: #000;
  opacity: 0;
  pointer-events: none;
}
.tag-selector-v2 .tag-selector-header .tag-selector-clear .icon {
  display: block;
  width: 9px;
  height: 9px;
}
.tag-selector-v2 .tag-selector-header .tag-selector-toggle .icon {
  position: absolute;
  right: 8px;
  top: 50%;
  display: block;
  width: 12px;
  height: 12px;
  color: #000;
  transform: translate(0, -50%);
  transition: transform 0.3s, top 0.3s;
}
.tag-selector-v2 .tag-selector-search {
  display: none;
  margin-top: 16px;
}
.tag-selector-v2 .tag-selector-search .MuiInputBase-root {
  margin-top: 0;
}
.tag-selector-v2 .tag-selector-body {
  display: none;
  margin-top: 16px;
  min-height: 100px;
  max-height: 340px;
  overflow: auto;
  position: relative;
  overscroll-behavior: none;
}
.tag-selector-v2 .tag-selector-body hr {
  margin-bottom: 16px;
  height: 1px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.tag-selector-v2 .tag-selector-body label {
  display: flex;
}
.tag-selector-v2 .tag-selector-body label .MuiTypography-root, .tag-selector-v2 .tag-selector-body label .name {
  text-transform: uppercase;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 14px;
}
.tag-selector-v2 .tag-selector-body label .name {
  width: 100%;
  display: block;
  padding: 5px;
  color: #000;
  transition: background-color 0.2s;
}
.tag-selector-v2 .tag-selector-body label .name:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.tag-selector-v2 .tag-selector-body label .tag-selector-subname {
  color: #444;
  font-size: 12px;
  line-height: 12px;
  word-break: break-all;
  text-transform: none;
}
.tag-selector-v2 .tag-selector-body label.selected {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tag-selector-v2 .tag-selector-body label.selected .MuiTypography-root, .tag-selector-v2 .tag-selector-body label.selected .name {
  font-weight: 600;
}
.tag-selector-v2 .tag-selector-body .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tag-selector-v2.expanded .tag-selector-header .tag-selector-toggle .icon {
  transform: translate(0, -50%) rotate(180deg);
}
.tag-selector-v2.expanded .tag-selector-search {
  display: block;
}
.tag-selector-v2.expanded .tag-selector-body {
  display: block;
}
.tag-selector-v2.selection .tag-selector-header .tag-selector-clear {
  opacity: 1;
  pointer-events: all;
  top: 11px;
}
.tag-selector-v2.selection .tag-selector-header .tag-selector-toggle .icon {
  top: 11px;
}
.tag-selector-v2 .MuiFormControlLabel-root {
  margin-bottom: 16px;
}
.tag-selector-v2.multiline .tag-selector-body label {
  align-items: flex-start;
}
.look-card {
  position: relative;
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #ccc;
  z-index: 1;
}
.look-card.draggable {
  cursor: move;
}
.look-card .card-header .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
}
.look-card .card-header .user-handle p {
  font-weight: 600;
}
.look-card .card-header .user-handle a {
  color: #000;
}
.look-card .look-card-fixed {
  padding-bottom: 136%;
  height: 0; /* fix for safari */
  overflow: hidden;
  width: 100%;
  position: relative;
}
.look-card .look-card-products {
  position: absolute;
  bottom: var(--look-card-padding);
  right: var(--look-card-padding);
  left: var(--look-card-padding);
  top: 0;
  height: calc(100% - var(--look-card-padding)); /* safari fix */
  display: grid;
  grid-gap: var(--look-card-padding);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
}
.look-card.look-card-no-header .look-card-products {
  top: var(--look-card-padding);
  height: calc(100% - var(--look-card-padding) * 2); /* safari fix */
}
.look-card .look-card-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--look-card-padding);
  height: 40px;
}
.look-card .look-card-action {
  display: block;
  position: absolute;
  color: #000;
  transition: color 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.look-card .look-card-action .icon {
  width: 16px;
  height: 16px;
}
.look-card .look-card-action:hover, .look-card .look-card-action.active {
  color: #eeb7be;
}
.look-card .look-card-action .icon-favorite-active, .look-card .look-card-action .icon-heart-active {
  color: #eeb7be;
}
.look-card .look-card-action-share {
  top: 10px;
  right: 30px;
}
.look-card .look-card-action-save {
  top: 10px;
  right: 5px;
}
.look-card .look-card-footer {
  padding: 0 var(--look-card-padding) var(--look-card-padding);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.look-card .look-card-footer .MuiButton-root {
  padding-left: 45px;
  padding-right: 45px;
}
.look-card .look-card-footer .MuiButton-root:first-child:last-child {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  font-weight: 700;
}
.look-card .share-panel {
  position: absolute;
  background-color: #f9f9f9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: var(--look-card-padding);
  z-index: 20;
}
.look-card .share-panel h3 {
  font-size: 26px;
  line-height: 33px;
  font-weight: 500;
  text-align: center;
  margin-top: 26px;
}
.look-card .share-panel a.close {
  position: absolute;
  display: block;
  z-index: 2;
  color: #000;
  background-color: rgba(255, 255, 255, 0.6);
  transition: opacity 0.2s;
  width: 32px;
  height: 32px;
  left: 10px;
  top: 10px;
}
.look-card .share-panel a.close .icon {
  margin-top: 8px;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.look-card .share-panel .providers {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.look-card .share-panel .providers a {
  display: block;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #000;
  padding: 8px;
  margin-bottom: 16px;
}
.look-card .share-panel .providers a:last-child {
  margin-bottom: 0;
}
.look-card .share-panel .providers a .icon {
  width: 24px;
  height: 24px;
}
.look-card .share-panel .MuiTextField-root {
  margin-bottom: 2px;
}
.look-card .share-panel .MuiTextField-root fieldset {
  border: 1px solid #ccc;
}
.look-card .share-panel .MuiTextField-root input {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
}
.look-card .look-card-published {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: rgba(0, 102, 0, 0.5);
  color: #fff;
  z-index: 30;
}
.look-card .look-card-published .icon {
  margin-top: 4px;
  margin-left: 4px;
  width: 8px;
  height: 8px;
}
.look-card .look-card-was-published {
  position: absolute;
  top: -10px;
  left: -6px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #cccccc;
  color: #fff;
  z-index: 30;
}
.look-card .look-card-was-published .icon {
  margin-top: 4px;
  margin-left: 4px;
  width: 8px;
  height: 8px;
}
.look-card .look-card-move {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 16px;
  height: 16px;
}
.look-card .look-card-move .icon {
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.look-card .card-selection {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #000;
  border: 2px solid #000;
  background-color: #fff;
  z-index: 10;
  cursor: pointer;
}
.look-card .card-selection .icon {
  margin-top: 3px;
  margin-left: 3px;
  width: 10px;
  height: 10px;
  display: none;
}
.look-card .card-selection.selected {
  color: #fff;
  background-color: #000;
}
.look-card .card-selection.selected .icon {
  display: block;
}
.look-card .card-selection.disabled {
  border: 2px solid #ccc;
  background-color: #ccc;
}
.look-card .card-selection.disabled.selected {
  background-color: #ccc;
}
.look-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.look-cards-item {
  width: 20%;
  padding: 0 10px 20px 10px;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.product-cards-item {
  width: 20%;
  padding: 0 5px 10px 5px;
}
.product-card, .look-product-card {
  position: relative;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: var(--product-card-padding);
  overflow: hidden;
}
.product-card .product-card-slot-image, .look-product-card .product-card-slot-image {
  position: absolute;
  z-index: 0;
  pointer-events: none;
}
.product-card > .loading, .look-product-card > .loading {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-card .product-card-image, .look-product-card .product-card-image {
  width: 100%;
  padding-bottom: 120%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
}
.product-card .product-card-footer-line-1, .look-product-card .product-card-footer-line-1 {
  display: flex;
}
.product-card .product-card-footer-line-2, .look-product-card .product-card-footer-line-2 {
  display: flex;
}
.product-card .product-card-footer, .look-product-card .product-card-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--product-card-padding);
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}
.product-card .product-card-footer p, .look-product-card .product-card-footer p {
  font-family: HelveticaNeue;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  width: 0;
  flex: 1;
  flex-basis: 100%;
}
.product-card .product-card-footer p:not(.product-card-name), .look-product-card .product-card-footer p:not(.product-card-name) {
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-card .product-card-footer p.product-card-brand, .look-product-card .product-card-footer p.product-card-brand {
  font-weight: 700;
  flex-basis: 70%;
}
.product-card .product-card-footer p.product-card-brand.with-sale, .look-product-card .product-card-footer p.product-card-brand.with-sale {
  flex-basis: 50%;
}
.product-card .product-card-footer p.product-card-price, .look-product-card .product-card-footer p.product-card-price {
  flex-basis: 30%;
  text-align: right;
}
.product-card .product-card-footer p.product-card-price.product-card-price-old-new, .look-product-card .product-card-footer p.product-card-price.product-card-price-old-new {
  flex-basis: 50%;
}
.product-card .product-card-footer p.product-card-price.product-card-price-sold-out, .look-product-card .product-card-footer p.product-card-price.product-card-price-sold-out {
  font-size: #999;
}
.product-card .product-card-footer p.product-card-price .product-card-price-discounted, .look-product-card .product-card-footer p.product-card-price .product-card-price-discounted {
  color: #d7606f;
}
.product-card .product-card-footer p.product-card-price .product-card-price-old, .look-product-card .product-card-footer p.product-card-price .product-card-price-old {
  text-decoration: line-through;
}
.product-card .product-card-footer p.product-card-price.product-card-price-converted, .look-product-card .product-card-footer p.product-card-price.product-card-price-converted {
  font-style: italic;
}
.product-card .product-card-footer p.product-card-name, .look-product-card .product-card-footer p.product-card-name {
  color: #4d4d4d;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: var(--product-card-padding);
  display: none;
}
.product-card.w-name .product-card-footer p.product-card-name, .look-product-card.w-name .product-card-footer p.product-card-name {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-card .product-card-action, .look-product-card .product-card-action {
  position: absolute;
  background-color: rgba(252, 252, 252, 0.7);
  transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9), color 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  z-index: 5;
  opacity: 0;
  color: #000;
}
.product-card .product-card-action .icon, .look-product-card .product-card-action .icon {
  width: 16px;
  height: 16px;
}
.product-card .product-card-action:hover, .product-card .product-card-action.active, .look-product-card .product-card-action:hover, .look-product-card .product-card-action.active {
  color: #eeb7be;
}
.product-card .product-card-action-save, .look-product-card .product-card-action-save {
  top: var(--look-card-padding);
  right: var(--look-card-padding);
}
.product-card .product-card-action-save.product-card-action-save-active, .look-product-card .product-card-action-save.product-card-action-save-active {
  color: #eeb7be;
}
.product-card .product-card-action-preview, .look-product-card .product-card-action-preview {
  top: 40px;
  right: var(--look-card-padding);
}
.product-card .product-card-action-close, .look-product-card .product-card-action-close {
  top: var(--look-card-padding);
  left: var(--look-card-padding);
}
.product-card .product-card-action-left,
.product-card .product-card-action-right, .look-product-card .product-card-action-left,
.look-product-card .product-card-action-right {
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 24px;
  height: 24px;
  bottom: 25px;
}
.product-card .product-card-action-left .icon,
.product-card .product-card-action-right .icon, .look-product-card .product-card-action-left .icon,
.look-product-card .product-card-action-right .icon {
  width: 10px;
  height: 10px;
}
.product-card .product-card-action-left, .look-product-card .product-card-action-left {
  left: var(--look-card-padding);
}
.product-card .product-card-action-right, .look-product-card .product-card-action-right {
  right: var(--look-card-padding);
}
.product-card .product-card-action-right .icon, .look-product-card .product-card-action-right .icon {
  transform: scale(-1);
}
.product-card .product-card-images-position, .look-product-card .product-card-images-position {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
  opacity: 0;
}
.product-card .product-card-images-position li, .look-product-card .product-card-images-position li {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 4px;
  border-radius: 50%;
}
.product-card .product-card-images-position li.product-card-images-current, .look-product-card .product-card-images-position li.product-card-images-current {
  background-color: rgb(0, 0, 0);
}
.product-card .product-card-images-position li:last-child, .look-product-card .product-card-images-position li:last-child {
  margin-right: 0;
}
.product-card .product-card-retailer, .look-product-card .product-card-retailer {
  position: absolute;
  top: var(--look-card-padding);
  left: var(--look-card-padding);
  right: var(--look-card-padding);
  height: 32px;
  line-height: 32px;
  background-color: rgba(252, 252, 252, 0.7);
  text-align: center;
  font-family: HelveticaNeue;
  color: #000;
  font-size: 9px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
  opacity: 0;
  pointer-events: none;
}
.product-card .product-card-sizes, .look-product-card .product-card-sizes {
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  padding-left: 0;
  background-color: #fff;
  top: calc(50% - 12px);
  transform: translateY(-50%);
  left: var(--look-card-padding);
  background-color: rgba(255, 255, 255, 0.6);
  min-width: 32px;
  transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
  pointer-events: none;
}
.product-card .product-card-sizes li, .look-product-card .product-card-sizes li {
  padding: 4px;
  list-style: none;
  font-size: 9px;
  line-height: 9px;
  text-align: center;
  font-family: HelveticaNeue;
  color: #000;
  text-transform: uppercase;
}
.product-card .product-card-sizes li.product-card-size-out-of-stock, .look-product-card .product-card-sizes li.product-card-size-out-of-stock {
  color: #ccc;
}
.product-card .product-card-sizes:after, .look-product-card .product-card-sizes:after {
  display: block;
  padding: 4px;
  padding-top: 0;
  font-size: 9px;
  line-height: 9px;
  text-align: center;
  font-family: HelveticaNeue;
  color: #000;
  font-weight: 600;
}
.product-card.product-card-h-1 .product-card-sizes-11 li, .look-product-card.product-card-h-1 .product-card-sizes-11 li {
  display: none;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:first-child, .look-product-card.product-card-h-1 .product-card-sizes-11 li:first-child {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(2), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(2) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(3), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(3) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(4), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(4) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(5), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(5) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(6), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(6) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(7), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(7) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(8), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(8) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(9), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(9) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(10), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(10) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(11), .look-product-card.product-card-h-1 .product-card-sizes-11 li:nth-child(11) {
  display: block;
}
.product-card.product-card-h-1 .product-card-sizes-11.product-card-sizes-12:after, .look-product-card.product-card-h-1 .product-card-sizes-11.product-card-sizes-12:after {
  content: "...";
}
.product-card.product-card-h-2 .product-card-sizes, .look-product-card.product-card-h-2 .product-card-sizes {
  top: calc(var(--look-card-padding) * 2 + 32px);
  transform: none;
}
.product-card.product-card-h-2 .product-card-sizes-4 li, .look-product-card.product-card-h-2 .product-card-sizes-4 li {
  display: none;
}
.product-card.product-card-h-2 .product-card-sizes-4 li:first-child, .look-product-card.product-card-h-2 .product-card-sizes-4 li:first-child {
  display: block;
}
.product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(2), .look-product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(2) {
  display: block;
}
.product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(3), .look-product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(3) {
  display: block;
}
.product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(4), .look-product-card.product-card-h-2 .product-card-sizes-4 li:nth-child(4) {
  display: block;
}
.product-card.product-card-h-2 .product-card-sizes-4.product-card-sizes-5:after, .look-product-card.product-card-h-2 .product-card-sizes-4.product-card-sizes-5:after {
  content: "...";
}
.product-card.product-card-h-3 .product-card-sizes, .look-product-card.product-card-h-3 .product-card-sizes {
  top: calc(var(--look-card-padding) * 2 + 32px);
  transform: none;
}
.product-card.product-card-h-3 .product-card-sizes-3 li, .look-product-card.product-card-h-3 .product-card-sizes-3 li {
  display: none;
}
.product-card.product-card-h-3 .product-card-sizes-3 li:first-child, .look-product-card.product-card-h-3 .product-card-sizes-3 li:first-child {
  display: block;
}
.product-card.product-card-h-3 .product-card-sizes-3 li:nth-child(2), .look-product-card.product-card-h-3 .product-card-sizes-3 li:nth-child(2) {
  display: block;
}
.product-card.product-card-h-3 .product-card-sizes-3 li:nth-child(3), .look-product-card.product-card-h-3 .product-card-sizes-3 li:nth-child(3) {
  display: block;
}
.product-card.product-card-h-3 .product-card-sizes-3.product-card-sizes-4:after, .look-product-card.product-card-h-3 .product-card-sizes-3.product-card-sizes-4:after {
  content: "...";
}
.product-card.product-card-h-4 .product-card-sizes, .product-card.product-card-h-5 .product-card-sizes, .product-card.product-card-h-6 .product-card-sizes, .look-product-card.product-card-h-4 .product-card-sizes, .look-product-card.product-card-h-5 .product-card-sizes, .look-product-card.product-card-h-6 .product-card-sizes {
  top: calc(var(--look-card-padding) * 2 + 32px);
  transform: none;
}
.product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2 li, .product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2 li, .product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2 li, .look-product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2 li, .look-product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2 li, .look-product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2 li {
  display: none;
}
.product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2 li:first-child, .product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2 li:first-child, .product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2 li:first-child, .look-product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2 li:first-child, .look-product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2 li:first-child, .look-product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2 li:first-child {
  display: block;
}
.product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2:after, .product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2:after, .product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2:after, .look-product-card.product-card-h-4 .product-card-sizes.product-card-sizes-2:after, .look-product-card.product-card-h-5 .product-card-sizes.product-card-sizes-2:after, .look-product-card.product-card-h-6 .product-card-sizes.product-card-sizes-2:after {
  content: "...";
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .product-card.product-card-h-2 .product-card-sizes, .product-card.product-card-h-3 .product-card-sizes, .product-card.product-card-h-4 .product-card-sizes, .product-card.product-card-h-5 .product-card-sizes, .product-card.product-card-h-6 .product-card-sizes, .look-product-card.product-card-h-2 .product-card-sizes, .look-product-card.product-card-h-3 .product-card-sizes, .look-product-card.product-card-h-4 .product-card-sizes, .look-product-card.product-card-h-5 .product-card-sizes, .look-product-card.product-card-h-6 .product-card-sizes {
    top: calc(var(--look-card-padding));
    transform: none;
  }
}
@media screen and (min-width: 1100px) {
  .product-card.product-card-h-2 .product-card-sizes, .look-product-card.product-card-h-2 .product-card-sizes {
    top: calc(50% - 12px);
    transform: translateY(-50%);
  }
}
.product-card .card-selection, .look-product-card .card-selection {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #000;
  border: 2px solid #000;
  background-color: #fff;
  z-index: 10;
  cursor: pointer;
}
.product-card .card-selection .icon, .look-product-card .card-selection .icon {
  margin-top: 3px;
  margin-left: 3px;
  width: 10px;
  height: 10px;
  display: none;
}
.product-card .card-selection.selected, .look-product-card .card-selection.selected {
  color: #fff;
  background-color: #000;
}
.product-card .card-selection.selected .icon, .look-product-card .card-selection.selected .icon {
  display: block;
}
.product-card .card-selection.disabled, .look-product-card .card-selection.disabled {
  border: 2px solid #ccc;
  background-color: #ccc;
}
.product-card .card-selection.disabled.selected, .look-product-card .card-selection.disabled.selected {
  background-color: #ccc;
}
.product-card:hover .product-card-action, .look-product-card:hover .product-card-action {
  opacity: 1;
}
.product-card:hover .product-card-retailer, .look-product-card:hover .product-card-retailer {
  opacity: 1;
}
.product-card:hover .product-card-sizes, .look-product-card:hover .product-card-sizes {
  opacity: 1;
}
.product-card:hover .product-card-images-position, .look-product-card:hover .product-card-images-position {
  opacity: 1;
}

.look-product-card-preview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 20;
  border: 0;
  display: flex;
  flex-direction: column;
}
.look-product-card-preview .product-card-image {
  padding-bottom: 0;
  flex: 1;
}
.look-product-card-preview .product-card-sizes {
  opacity: 1;
}
.look-product-card-preview .product-card-retailer {
  opacity: 1;
  font-size: 12px;
  left: calc(var(--look-card-padding) * 2 + 32px);
  right: calc(var(--look-card-padding) * 2 + 32px);
}
.look-product-card-preview .product-card-action {
  opacity: 1;
}
.look-product-card-preview .product-card-footer {
  position: relative;
}
.look-product-card-preview .product-card-footer p.name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.look-product-card-preview .product-card-action-left,
.look-product-card-preview .product-card-action-right {
  bottom: 45px;
}
.look-product-card-preview .product-card-images-position {
  bottom: 55px;
}
.user-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.user-contact h4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.08em;
  margin-bottom: 20px;
}
.user-contact h4 a {
  color: #000;
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: auto;
}
.user-contact h4 a:last-child {
  margin-left: 10px;
  font-weight: 500;
}
.user-contact .MuiInput-underline {
  margin-right: 20px;
  margin-top: 10px;
}
.user-contact .MuiInput-underline .MuiSelect-icon {
  margin-top: 4px;
  margin-right: 4px;
}
.user-contact .MuiInput-underline:before {
  border: 0;
}
.user-contact .MuiInput-underline:hover:before, .user-contact .MuiInput-underline:active:before, .user-contact .MuiInput-underline:focus:before, .user-contact .MuiInput-underline.Mui-focused:before {
  border: 0 !important;
}
.user-contact .MuiInput-underline:hover:after, .user-contact .MuiInput-underline:active:after, .user-contact .MuiInput-underline:focus:after, .user-contact .MuiInput-underline.Mui-focused:after {
  border: 0 !important;
}
.user-contact .MuiSelect-root {
  padding-left: 0;
}
.user-contact .MuiTextField-root {
  width: 100%;
  margin-left: 0;
}
.user-contact .MuiTextField-root fieldset {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.user-contact .MuiInput-underline + .MuiTextField-root {
  flex: 1;
  width: auto;
}
.user-contact .MuiFormControlLabel-root {
  margin-top: 10px;
}

.user-contact-collapsed {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: HelveticaNeue;
}
.user-contact-collapsed p {
  margin-top: 0;
}
.user-contact-collapsed p b {
  text-transform: uppercase;
  margin-right: 10px;
}
.user-contact-collapsed p span {
  color: #666;
}
.user-contact-collapsed .actions {
  margin-left: auto;
}
.user-contact-collapsed .actions a {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-left: 5px;
}
.user-contact-collapsed .actions a .icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  color: #000;
  margin-top: 3px;
  margin-left: 3px;
}
.user-contact-collapsed.disabled {
  color: #ccc;
}
.user-contact-collapsed.disabled p span {
  color: #ccc;
}
.user-contact-collapsed.disabled .user-contact-collapsed-tick {
  border-color: #ccc;
  background-color: #ccc;
}
.user-contact-collapsed.disabled .actions a .icon {
  color: #ccc;
}

.user-contact-collapsed-tick {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  background-color: #000;
  border-radius: 50%;
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-contact-collapsed-tick .icon {
  width: 20px;
  height: 20px;
  color: #fff;
}

.user-contact-add {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user-contact-phone {
  display: flex;
  width: 100%;
}

.select-dial-codes .MuiSelect-select {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.select-dial-codes .dial-code-code {
  margin-right: 10px;
}
.select-dial-codes .MuiSelect-icon {
  width: 10px;
  height: 10px;
  right: 10px;
  top: 17px;
}

.menu-dial-codes .dial-code-code {
  min-width: 60px;
}
.theme-light .MuiButton-root, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root,
.theme-dark .MuiButton-root,
.theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
}
@media screen and (min-width: 600px) {
  .theme-light .MuiButton-root, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root,
  .theme-dark .MuiButton-root,
  .theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root {
    font-size: 16px;
  }
}
.theme-light .MuiButton-root .MuiButton-startIcon, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-startIcon,
.theme-dark .MuiButton-root .MuiButton-startIcon,
.theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-startIcon {
  display: flex;
  align-items: center;
}
.theme-light .MuiButton-root .MuiButton-startIcon .icon, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-startIcon .icon,
.theme-dark .MuiButton-root .MuiButton-startIcon .icon,
.theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-startIcon .icon {
  width: 16px;
  height: 16px;
}
.theme-light .MuiButton-root .MuiButton-endIcon, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-endIcon,
.theme-dark .MuiButton-root .MuiButton-endIcon,
.theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-endIcon {
  display: flex;
  align-items: center;
}
.theme-light .MuiButton-root .MuiButton-endIcon .icon, .theme-light .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-endIcon .icon,
.theme-dark .MuiButton-root .MuiButton-endIcon .icon,
.theme-dark .MuiIconButton-root.icon-button.MuiToggleButton-root .MuiButton-endIcon .icon {
  width: 16px;
  height: 16px;
}
.theme-light .MuiIconButton-root.icon-button,
.theme-dark .MuiIconButton-root.icon-button {
  border-radius: 0;
  padding: 10px;
}
.theme-light .MuiIconButton-root.icon-button .icon,
.theme-dark .MuiIconButton-root.icon-button .icon {
  width: 20px;
  height: 20px;
}
.theme-light .MuiButton-sizeLarge,
.theme-dark .MuiButton-sizeLarge {
  padding-left: 45px;
  padding-right: 45px;
  font-size: 16px;
  line-height: 50px;
}
.theme-light .MuiButton-sizeSmall,
.theme-dark .MuiButton-sizeSmall {
  font-weight: 600;
  font-size: 13px;
}
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root,
.theme-dark .MuiToggleButtonGroup-root .MuiToggleButton-root {
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: none;
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root .icon,
.theme-dark .MuiToggleButtonGroup-root .MuiToggleButton-root .icon {
  width: 16px;
  height: 16px;
}
@media screen and (min-width: 600px) {
  .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root,
  .theme-dark .MuiToggleButtonGroup-root .MuiToggleButton-root {
    font-size: 16px;
  }
}
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root .MuiToggleButton-label,
.theme-dark .MuiToggleButtonGroup-root .MuiToggleButton-root .MuiToggleButton-label {
  text-transform: none;
}

html.theme-dark .theme-light .MuiButton-outlined,
.theme-light .MuiButton-outlined {
  color: #000;
  font-weight: 500;
  border-color: #000;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s, background-color 0.3s;
}
html.theme-dark .theme-light .MuiButton-outlined:hover,
.theme-light .MuiButton-outlined:hover {
  border-color: #062a3c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
html.theme-dark .theme-light .MuiButton-outlined.Mui-disabled,
.theme-light .MuiButton-outlined.Mui-disabled {
  border-color: #f5f5f5;
  box-shadow: none;
}
html.theme-dark .theme-light .MuiButton-outlined.Mui-disabled .MuiButton-startIcon svg,
.theme-light .MuiButton-outlined.Mui-disabled .MuiButton-startIcon svg {
  color: #000;
}
html.theme-dark .theme-light .MuiButton-contained.icon-button .icon,
.theme-light .MuiButton-contained.icon-button .icon {
  color: #fff;
}
html.theme-dark .theme-light .MuiButton-outlined.icon-button .icon,
.theme-light .MuiButton-outlined.icon-button .icon {
  color: #000;
}
html.theme-dark .theme-light .MuiButton-text,
.theme-light .MuiButton-text {
  background-color: transparent;
  letter-spacing: 0.08;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  text-decoration: underline;
}
html.theme-dark .theme-light .MuiButton-text:hover,
.theme-light .MuiButton-text:hover {
  background-color: transparent;
}
html.theme-dark .theme-light .MuiButton-text.Mui-disabled,
.theme-light .MuiButton-text.Mui-disabled {
  color: #999;
}
html.theme-dark .theme-light .MuiButton-contained,
.theme-light .MuiButton-contained {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.95);
  background-color: #000;
  transition: background-color 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
}
html.theme-dark .theme-light .MuiButton-contained:hover,
.theme-light .MuiButton-contained:hover {
  background-color: #333333;
}
html.theme-dark .theme-light .MuiButton-contained.Mui-disabled,
.theme-light .MuiButton-contained.Mui-disabled {
  color: #fff;
  background-color: #b3b3b3;
}
html.theme-dark .theme-light .MuiButton-contained.Mui-disabled .MuiButton-startIcon svg,
.theme-light .MuiButton-contained.Mui-disabled .MuiButton-startIcon svg {
  color: #000;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root {
  box-shadow: none;
  transition: none;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root:hover,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root:hover {
  background-color: #444;
  color: #fff;
  box-shadow: none;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-disabled,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-disabled {
  color: #fff;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
  box-shadow: none;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected {
  background-color: #444;
  border-color: #444;
  color: #fff;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected:hover,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected:hover {
  background-color: #444;
  color: #fff;
}
html.theme-dark .theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected.Mui-disabled,
.theme-light .MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected.Mui-disabled {
  color: #b3b3b3;
  border-color: #b3b3b3;
  box-shadow: none;
}

html.theme-light .theme-dark .MuiButton-contained, html.theme-light .theme-dark .MuiIconButton-contained.icon-button,
.theme-dark .MuiButton-contained,
.theme-dark .MuiIconButton-contained.icon-button {
  color: #000;
  background-color: #fff;
  transition: background-color 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9);
}
html.theme-light .theme-dark .MuiButton-contained:hover, html.theme-light .theme-dark .MuiIconButton-contained.icon-button:hover,
.theme-dark .MuiButton-contained:hover,
.theme-dark .MuiIconButton-contained.icon-button:hover {
  background-color: #e6e6e6;
}
html.theme-light .theme-dark .MuiButton-contained.Mui-disabled, html.theme-light .theme-dark .MuiIconButton-contained.icon-button.Mui-disabled,
.theme-dark .MuiButton-contained.Mui-disabled,
.theme-dark .MuiIconButton-contained.icon-button.Mui-disabled {
  background-color: #d0d0d0;
}
html.theme-light .theme-dark .MuiButton-contained.Mui-disabled .MuiButton-startIcon svg, html.theme-light .theme-dark .MuiIconButton-contained.icon-button.Mui-disabled .MuiButton-startIcon svg,
.theme-dark .MuiButton-contained.Mui-disabled .MuiButton-startIcon svg,
.theme-dark .MuiIconButton-contained.icon-button.Mui-disabled .MuiButton-startIcon svg {
  color: #000;
}
html.theme-light .theme-dark .MuiButton-outlined, html.theme-light .theme-dark .MuiIconButton-root.icon-button,
.theme-dark .MuiButton-outlined,
.theme-dark .MuiIconButton-root.icon-button {
  color: #fff;
  border-color: #262626;
  background-color: #000;
  box-shadow: none;
}
html.theme-light .theme-dark .MuiButton-outlined.Mui-disabled, html.theme-light .theme-dark .MuiIconButton-root.icon-button.Mui-disabled,
.theme-dark .MuiButton-outlined.Mui-disabled,
.theme-dark .MuiIconButton-root.icon-button.Mui-disabled {
  border-color: #262626;
  color: #262626;
  box-shadow: none;
}
html.theme-light .theme-dark .MuiButton-outlined.Mui-disabled .MuiButton-startIcon svg, html.theme-light .theme-dark .MuiIconButton-root.icon-button.Mui-disabled .MuiButton-startIcon svg,
.theme-dark .MuiButton-outlined.Mui-disabled .MuiButton-startIcon svg,
.theme-dark .MuiIconButton-root.icon-button.Mui-disabled .MuiButton-startIcon svg {
  color: #222;
}
.theme-light .MuiFormControlLabel-root,
.theme-dark .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
.theme-light .MuiFormControlLabel-root .MuiCheckbox-root,
.theme-dark .MuiFormControlLabel-root .MuiCheckbox-root {
  margin-right: 10px;
}
.theme-light .MuiCheckbox-root,
.theme-dark .MuiCheckbox-root {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 0;
}
.theme-light .MuiCheckbox-root .MuiIconButton-label,
.theme-dark .MuiCheckbox-root .MuiIconButton-label {
  width: 20px;
  height: 20px;
  position: relative;
}
.theme-light .MuiCheckbox-root .MuiIconButton-label:before,
.theme-dark .MuiCheckbox-root .MuiIconButton-label:before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 0;
  z-index: -1;
}
.theme-light .MuiCheckbox-root .MuiSvgIcon-root,
.theme-dark .MuiCheckbox-root .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  display: block;
}
.theme-light .MuiCheckbox-root .checkbox-blank,
.theme-dark .MuiCheckbox-root .checkbox-blank {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
}
.theme-light .MuiCheckbox-root.MuiIconButton-colorSecondary:hover,
.theme-dark .MuiCheckbox-root.MuiIconButton-colorSecondary:hover {
  background-color: transparent;
}
.theme-light .MuiCheckbox-root + .MuiTypography-root,
.theme-dark .MuiCheckbox-root + .MuiTypography-root {
  vertical-align: -1px;
}

html.theme-dark .theme-light .MuiCheckbox-root,
.theme-light .MuiCheckbox-root {
  color: #000;
}
html.theme-dark .theme-light .MuiCheckbox-root .MuiIconButton-label,
.theme-light .MuiCheckbox-root .MuiIconButton-label {
  color: #000;
}
html.theme-dark .theme-light .MuiCheckbox-root .MuiIconButton-label:before,
.theme-light .MuiCheckbox-root .MuiIconButton-label:before {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiCheckbox-root.Mui-focusVisible,
.theme-light .MuiCheckbox-root.Mui-focusVisible {
  color: #000;
}
html.theme-dark .theme-light .MuiCheckbox-root.Mui-checked,
.theme-light .MuiCheckbox-root.Mui-checked {
  color: #000;
}
html.theme-dark .theme-light .MuiCheckbox-root.Mui-disabled,
.theme-light .MuiCheckbox-root.Mui-disabled {
  color: #666;
}
html.theme-dark .theme-light .MuiCheckbox-root.Mui-disabled .MuiIconButton-label,
.theme-light .MuiCheckbox-root.Mui-disabled .MuiIconButton-label {
  color: #666;
}
html.theme-dark .theme-light .MuiCheckbox-root.error,
.theme-light .MuiCheckbox-root.error {
  color: rgba(215, 96, 111, 0.5);
}
html.theme-dark .theme-light .MuiCheckbox-root .checkbox-blank,
.theme-light .MuiCheckbox-root .checkbox-blank {
  border: 1px solid #000;
}
html.theme-dark .theme-light .MuiCheckbox-root.MuiCheckbox-indeterminate,
.theme-light .MuiCheckbox-root.MuiCheckbox-indeterminate {
  border: 1px solid #000;
}
html.theme-dark .theme-light .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiSvgIcon-root,
.theme-light .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.1);
}

html.theme-light .theme-dark .MuiCheckbox-root,
.theme-dark .MuiCheckbox-root {
  color: #fff;
}
html.theme-light .theme-dark .MuiCheckbox-root .MuiIconButton-label:before,
.theme-dark .MuiCheckbox-root .MuiIconButton-label:before {
  background-color: #000;
}
html.theme-light .theme-dark .MuiCheckbox-root.Mui-focusVisible,
.theme-dark .MuiCheckbox-root.Mui-focusVisible {
  color: #fff;
}
html.theme-light .theme-dark .MuiCheckbox-root.Mui-checked,
.theme-dark .MuiCheckbox-root.Mui-checked {
  color: #fff;
}
html.theme-light .theme-dark .MuiCheckbox-root.Mui-disabled,
.theme-dark .MuiCheckbox-root.Mui-disabled {
  color: #666;
}
html.theme-light .theme-dark .MuiCheckbox-root.Mui-disabled .MuiIconButton-label,
.theme-dark .MuiCheckbox-root.Mui-disabled .MuiIconButton-label {
  color: #666;
}
html.theme-light .theme-dark .MuiCheckbox-root.error,
.theme-dark .MuiCheckbox-root.error {
  color: rgba(215, 96, 111, 0.5);
}
html.theme-light .theme-dark .MuiCheckbox-root .checkbox-blank,
.theme-dark .MuiCheckbox-root .checkbox-blank {
  border: 1px solid #fff;
}
.theme-light .MuiChip-root.MuiChip-sizeSmall,
.theme-dark .MuiChip-root.MuiChip-sizeSmall {
  border-radius: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}
.theme-light .MuiChip-root.MuiChip-sizeSmall .MuiChip-deleteIcon,
.theme-dark .MuiChip-root.MuiChip-sizeSmall .MuiChip-deleteIcon {
  width: 14px;
  height: 14px;
  transition: color 0.2s;
  margin-right: 5px;
}
.theme-light .MuiChip-root.MuiChip-sizeSmall .MuiChip-label,
.theme-dark .MuiChip-root.MuiChip-sizeSmall .MuiChip-label {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
  line-height: 30px;
  text-transform: uppercase;
}

html.theme-dark .theme-light .MuiChip-root,
.theme-light .MuiChip-root {
  background-color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root .MuiChip-deleteIcon,
.theme-light .MuiChip-root .MuiChip-deleteIcon {
  color: #f4f0eb;
}
html.theme-dark .theme-light .MuiChip-root:hover,
.theme-light .MuiChip-root:hover {
  background-color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-outlined,
.theme-light .MuiChip-root.MuiChip-outlined {
  background-color: #fff;
  border-color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-outlined .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-outlined .MuiChip-deleteIcon {
  color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-outlined:hover,
.theme-light .MuiChip-root.MuiChip-outlined:hover {
  background-color: #fff;
  border-color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable .MuiChip-deleteIcon, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-clickable .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-deletable .MuiChip-deleteIcon {
  color: #d0bfa9;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable:hover, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable:hover,
.theme-light .MuiChip-root.MuiChip-clickable:hover,
.theme-light .MuiChip-root.MuiChip-deletable:hover {
  background-color: #EEEDEB;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable:hover .MuiChip-deleteIcon, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable:hover .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-clickable:hover .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-deletable:hover .MuiChip-deleteIcon {
  color: #ac8d67;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined .MuiChip-deleteIcon, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined .MuiChip-deleteIcon {
  color: #d7d4cf;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined:hover, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined:hover,
.theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined:hover,
.theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined:hover {
  background-color: #fff;
  border-color: #bfbbb4;
}
html.theme-dark .theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined:hover .MuiChip-deleteIcon, html.theme-dark .theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined:hover .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-clickable.MuiChip-outlined:hover .MuiChip-deleteIcon,
.theme-light .MuiChip-root.MuiChip-deletable.MuiChip-outlined:hover .MuiChip-deleteIcon {
  color: #908a7d;
}
.theme-light .textarea-plain,
.theme-dark .textarea-plain {
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  line-height: 18px;
  border-width: 1px;
  transition: border-color 0.2s;
}
.theme-light .textarea-plain:focus,
.theme-dark .textarea-plain:focus {
  border-width: 1px;
}
.theme-light .MuiTextField-root, .theme-light .MuiSelectField-root,
.theme-dark .MuiTextField-root,
.theme-dark .MuiSelectField-root {
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.theme-light .MuiTextField-root label, .theme-light .MuiSelectField-root label,
.theme-dark .MuiTextField-root label,
.theme-dark .MuiSelectField-root label {
  transform: none;
  transition: none;
  position: static;
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 16px;
  margin-left: 3px;
}
.theme-light .MuiTextField-root fieldset, .theme-light .MuiSelectField-root fieldset,
.theme-dark .MuiTextField-root fieldset,
.theme-dark .MuiSelectField-root fieldset {
  top: 0;
}
.theme-light .MuiTextField-root legend, .theme-light .MuiSelectField-root legend,
.theme-dark .MuiTextField-root legend,
.theme-dark .MuiSelectField-root legend {
  display: none;
}
.theme-light .MuiTextField-root input, .theme-light .MuiTextField-root textarea, .theme-light .MuiTextField-root select, .theme-light .MuiTextField-root option, .theme-light .MuiSelectField-root input, .theme-light .MuiSelectField-root textarea, .theme-light .MuiSelectField-root select, .theme-light .MuiSelectField-root option,
.theme-dark .MuiTextField-root input,
.theme-dark .MuiTextField-root textarea,
.theme-dark .MuiTextField-root select,
.theme-dark .MuiTextField-root option,
.theme-dark .MuiSelectField-root input,
.theme-dark .MuiSelectField-root textarea,
.theme-dark .MuiSelectField-root select,
.theme-dark .MuiSelectField-root option {
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  box-sizing: border-box;
}
.theme-light .MuiTextField-root input, .theme-light .MuiSelectField-root input,
.theme-dark .MuiTextField-root input,
.theme-dark .MuiSelectField-root input {
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 40px;
}
.theme-light .MuiTextField-root input::-moz-placeholder, .theme-light .MuiSelectField-root input::-moz-placeholder, .theme-dark .MuiTextField-root input::-moz-placeholder, .theme-dark .MuiSelectField-root input::-moz-placeholder {
  line-height: 40px;
  overflow: visible;
}
.theme-light .MuiTextField-root input::placeholder, .theme-light .MuiSelectField-root input::placeholder,
.theme-dark .MuiTextField-root input::placeholder,
.theme-dark .MuiSelectField-root input::placeholder {
  line-height: 40px;
  overflow: visible;
}
.theme-light .MuiTextField-root p, .theme-light .MuiSelectField-root p,
.theme-dark .MuiTextField-root p,
.theme-dark .MuiSelectField-root p {
  margin-top: 2px;
  margin-left: 3px;
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: 400;
}
.theme-light .MuiTextField-root > div, .theme-light .MuiSelectField-root > div,
.theme-dark .MuiTextField-root > div,
.theme-dark .MuiSelectField-root > div {
  margin-top: 5px;
  transition: background-color 0.2s;
}
.theme-light .MuiTextField-root > div fieldset, .theme-light .MuiSelectField-root > div fieldset,
.theme-dark .MuiTextField-root > div fieldset,
.theme-dark .MuiSelectField-root > div fieldset {
  border-width: 1px;
  transition: border-color 0.2s;
}
.theme-light .MuiTextField-root > div.Mui-focused fieldset, .theme-light .MuiSelectField-root > div.Mui-focused fieldset,
.theme-dark .MuiTextField-root > div.Mui-focused fieldset,
.theme-dark .MuiSelectField-root > div.Mui-focused fieldset {
  border-width: 1px;
}
.theme-light .MuiTextField-root .MuiOutlinedInput-adornedEnd, .theme-light .MuiSelectField-root .MuiOutlinedInput-adornedEnd,
.theme-dark .MuiTextField-root .MuiOutlinedInput-adornedEnd,
.theme-dark .MuiSelectField-root .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}
.theme-light .MuiTextField-root .MuiOutlinedInput-root, .theme-light .MuiSelectField-root .MuiOutlinedInput-root,
.theme-dark .MuiTextField-root .MuiOutlinedInput-root,
.theme-dark .MuiSelectField-root .MuiOutlinedInput-root {
  border-radius: 0;
}
.theme-light .MuiTextField-root .MuiInputBase-sizeSmall input, .theme-light .MuiSelectField-root .MuiInputBase-sizeSmall input,
.theme-dark .MuiTextField-root .MuiInputBase-sizeSmall input,
.theme-dark .MuiSelectField-root .MuiInputBase-sizeSmall input {
  padding: 3px 8px;
  height: 30px;
  line-height: 30px;
}
.theme-light .MuiTextField-root .MuiInputBase-sizeSmall input::-moz-placeholder, .theme-light .MuiSelectField-root .MuiInputBase-sizeSmall input::-moz-placeholder, .theme-dark .MuiTextField-root .MuiInputBase-sizeSmall input::-moz-placeholder, .theme-dark .MuiSelectField-root .MuiInputBase-sizeSmall input::-moz-placeholder {
  line-height: 30px;
}
.theme-light .MuiTextField-root .MuiInputBase-sizeSmall input::placeholder, .theme-light .MuiSelectField-root .MuiInputBase-sizeSmall input::placeholder,
.theme-dark .MuiTextField-root .MuiInputBase-sizeSmall input::placeholder,
.theme-dark .MuiSelectField-root .MuiInputBase-sizeSmall input::placeholder {
  line-height: 30px;
}
.theme-light .MuiSelectField-root .MuiSelect-root,
.theme-dark .MuiSelectField-root .MuiSelect-root {
  padding: 10.5px 24px 10.5px 14px;
}
.theme-light .MuiAutocomplete-popper li,
.theme-dark .MuiAutocomplete-popper li {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 400;
}
.theme-light .MuiFormControlLabel-label,
.theme-dark .MuiFormControlLabel-label {
  font-family: HelveticaNeue;
  font-size: 16px;
}
.theme-light .MuiYearPicker-root,
.theme-dark .MuiYearPicker-root {
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-left: 0px;
  padding-bottom: 10px;
}
.theme-light .MuiYearPicker-root .PrivatePickersYear-root:last-child .Mui-selected,
.theme-dark .MuiYearPicker-root .PrivatePickersYear-root:last-child .Mui-selected {
  margin-left: 20px;
}

html.theme-dark .theme-light .MuiPickersDay-daySelected,
.theme-light .MuiPickersDay-daySelected {
  background-color: #000;
}
html.theme-dark .theme-light .MuiPickersDay-current,
.theme-light .MuiPickersDay-current {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}
html.theme-dark .theme-light .textarea-plain,
.theme-light .textarea-plain {
  border-color: #000;
}
html.theme-dark .theme-light .MuiTextField-root label, html.theme-dark .theme-light .MuiSelectField-root label,
.theme-light .MuiTextField-root label,
.theme-light .MuiSelectField-root label {
  color: #000;
}
html.theme-dark .theme-light .MuiTextField-root p, html.theme-dark .theme-light .MuiSelectField-root p,
.theme-light .MuiTextField-root p,
.theme-light .MuiSelectField-root p {
  color: rgba(0, 0, 0, 0.6);
}
html.theme-dark .theme-light .MuiTextField-root .icon, html.theme-dark .theme-light .MuiSelectField-root .icon,
.theme-light .MuiTextField-root .icon,
.theme-light .MuiSelectField-root .icon {
  color: #ccc;
}
html.theme-dark .theme-light .MuiTextField-root > div, html.theme-dark .theme-light .MuiSelectField-root > div,
.theme-light .MuiTextField-root > div,
.theme-light .MuiSelectField-root > div {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiTextField-root > div fieldset, html.theme-dark .theme-light .MuiSelectField-root > div fieldset,
.theme-light .MuiTextField-root > div fieldset,
.theme-light .MuiSelectField-root > div fieldset {
  border-color: #000;
}
html.theme-dark .theme-light .MuiTextField-root > div:hover, html.theme-dark .theme-light .MuiSelectField-root > div:hover,
.theme-light .MuiTextField-root > div:hover,
.theme-light .MuiSelectField-root > div:hover {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiTextField-root > div:hover fieldset, html.theme-dark .theme-light .MuiSelectField-root > div:hover fieldset,
.theme-light .MuiTextField-root > div:hover fieldset,
.theme-light .MuiSelectField-root > div:hover fieldset {
  border-color: #000;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-focusVisible, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-focusVisible,
.theme-light .MuiTextField-root > div.Mui-focusVisible,
.theme-light .MuiSelectField-root > div.Mui-focusVisible {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-focusVisible fieldset, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-focusVisible fieldset,
.theme-light .MuiTextField-root > div.Mui-focusVisible fieldset,
.theme-light .MuiSelectField-root > div.Mui-focusVisible fieldset {
  border-color: #000;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-focusVisible .MuiInputAdornment-root, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-focusVisible .MuiInputAdornment-root,
.theme-light .MuiTextField-root > div.Mui-focusVisible .MuiInputAdornment-root,
.theme-light .MuiSelectField-root > div.Mui-focusVisible .MuiInputAdornment-root {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-focused, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-focused,
.theme-light .MuiTextField-root > div.Mui-focused,
.theme-light .MuiSelectField-root > div.Mui-focused {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-focused fieldset, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-focused fieldset,
.theme-light .MuiTextField-root > div.Mui-focused fieldset,
.theme-light .MuiSelectField-root > div.Mui-focused fieldset {
  border-color: #000;
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-error, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-error,
.theme-light .MuiTextField-root > div.Mui-error,
.theme-light .MuiSelectField-root > div.Mui-error {
  background-color: rgba(238, 183, 190, 0.3);
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-error fieldset, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-error fieldset,
.theme-light .MuiTextField-root > div.Mui-error fieldset,
.theme-light .MuiSelectField-root > div.Mui-error fieldset {
  border-color: rgba(215, 96, 111, 0.5);
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-error p, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-error p,
.theme-light .MuiTextField-root > div.Mui-error p,
.theme-light .MuiSelectField-root > div.Mui-error p {
  color: rgba(215, 96, 111, 0.5);
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-error:hover fieldset, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-error:hover fieldset,
.theme-light .MuiTextField-root > div.Mui-error:hover fieldset,
.theme-light .MuiSelectField-root > div.Mui-error:hover fieldset {
  border-color: rgba(215, 96, 111, 0.5);
}
html.theme-dark .theme-light .MuiTextField-root > div.Mui-disabled fieldset, html.theme-dark .theme-light .MuiSelectField-root > div.Mui-disabled fieldset,
.theme-light .MuiTextField-root > div.Mui-disabled fieldset,
.theme-light .MuiSelectField-root > div.Mui-disabled fieldset {
  border-color: #e7e7e7;
}
html.theme-dark .theme-light .MuiTextField-root .MuiFormHelperText-root.Mui-error, html.theme-dark .theme-light .MuiSelectField-root .MuiFormHelperText-root.Mui-error,
.theme-light .MuiTextField-root .MuiFormHelperText-root.Mui-error,
.theme-light .MuiSelectField-root .MuiFormHelperText-root.Mui-error {
  color: #d7606f;
}
html.theme-dark .theme-light .MuiAutocomplete-popper li,
.theme-light .MuiAutocomplete-popper li {
  color: #000;
}
html.theme-dark .theme-light .MuiFormControlLabel-label,
.theme-light .MuiFormControlLabel-label {
  color: #000;
}

html.theme-light .theme-dark .MuiTextField-root label,
.theme-dark .MuiTextField-root label {
  color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root p,
.theme-dark .MuiTextField-root p {
  color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div,
.theme-dark .MuiTextField-root div {
  background-color: #000;
}
html.theme-light .theme-dark .MuiTextField-root div fieldset,
.theme-dark .MuiTextField-root div fieldset {
  border-color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div input,
.theme-dark .MuiTextField-root div input {
  color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div:hover,
.theme-dark .MuiTextField-root div:hover {
  background-color: #000;
}
html.theme-light .theme-dark .MuiTextField-root div:hover fieldset,
.theme-dark .MuiTextField-root div:hover fieldset {
  border-color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-focusVisible,
.theme-dark .MuiTextField-root div.Mui-focusVisible {
  background-color: #000;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-focusVisible fieldset,
.theme-dark .MuiTextField-root div.Mui-focusVisible fieldset {
  border-color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-focused,
.theme-dark .MuiTextField-root div.Mui-focused {
  background-color: #000;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-focused fieldset,
.theme-dark .MuiTextField-root div.Mui-focused fieldset {
  border-color: #fff;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-error,
.theme-dark .MuiTextField-root div.Mui-error {
  background-color: #3b242b;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-error fieldset,
.theme-dark .MuiTextField-root div.Mui-error fieldset {
  border-color: #efa0b9;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-error + p,
.theme-dark .MuiTextField-root div.Mui-error + p {
  color: #efa0b9;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-error:hover fieldset,
.theme-dark .MuiTextField-root div.Mui-error:hover fieldset {
  border-color: #efa0b9;
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-disabled input,
.theme-dark .MuiTextField-root div.Mui-disabled input {
  color: rgba(255, 255, 255, 0.5);
}
html.theme-light .theme-dark .MuiTextField-root div.Mui-disabled fieldset,
.theme-dark .MuiTextField-root div.Mui-disabled fieldset {
  border-color: rgba(255, 255, 255, 0.5);
}
html.theme-light .theme-dark .MuiFormControlLabel-label,
.theme-dark .MuiFormControlLabel-label {
  color: rgba(255, 255, 255, 0.75);
}

.password-field-eye {
  height: 100% !important;
  align-items: center !important;
}
.password-field-eye a {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.password-field-eye .icon {
  width: 20px;
  height: 20px;
  color: #000;
}
.theme-light .MuiFormControlLabel-root,
.theme-dark .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
.theme-light .MuiFormControlLabel-root .MuiRadio-root,
.theme-dark .MuiFormControlLabel-root .MuiRadio-root {
  margin-right: 10px;
}
.theme-light .MuiRadio-root,
.theme-dark .MuiRadio-root {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 0;
}
.theme-light .MuiRadio-root .MuiIconButton-label,
.theme-dark .MuiRadio-root .MuiIconButton-label {
  width: 20px;
  height: 20px;
  position: relative;
}
.theme-light .MuiRadio-root .MuiIconButton-label:before,
.theme-dark .MuiRadio-root .MuiIconButton-label:before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  z-index: -1;
}
.theme-light .MuiRadio-root .MuiSvgIcon-root,
.theme-dark .MuiRadio-root .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  display: block;
}
.theme-light .MuiRadio-root .radio-blank,
.theme-dark .MuiRadio-root .radio-blank {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid transparent;
}
.theme-light .MuiRadio-root.MuiIconButton-colorSecondary:hover,
.theme-dark .MuiRadio-root.MuiIconButton-colorSecondary:hover {
  background-color: transparent;
}
.theme-light .MuiRadio-root + .MuiTypography-root,
.theme-dark .MuiRadio-root + .MuiTypography-root {
  vertical-align: -1px;
}

html.theme-dark .theme-light .MuiRadio-root,
.theme-light .MuiRadio-root {
  color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root .MuiIconButton-label,
.theme-light .MuiRadio-root .MuiIconButton-label {
  color: #000;
}
html.theme-dark .theme-light .MuiRadio-root .MuiIconButton-label:before,
.theme-light .MuiRadio-root .MuiIconButton-label:before {
  background-color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root.error,
.theme-light .MuiRadio-root.error {
  color: rgba(215, 96, 111, 0.5);
}
html.theme-dark .theme-light .MuiRadio-root .radio-blank,
.theme-light .MuiRadio-root .radio-blank {
  border: 1px solid #000;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-focusVisible,
.theme-light .MuiRadio-root.Mui-focusVisible {
  color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-focusVisible .MuiIconButton-label,
.theme-light .MuiRadio-root.Mui-focusVisible .MuiIconButton-label {
  color: #000;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-focusVisible .MuiIconButton-label:before,
.theme-light .MuiRadio-root.Mui-focusVisible .MuiIconButton-label:before {
  background-color: #ddd;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-checked,
.theme-light .MuiRadio-root.Mui-checked {
  color: #000;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label,
.theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label {
  color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label:before,
.theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label:before {
  border: 1px solid #000;
  background-color: #000;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label .MuiSvgIcon-root,
.theme-light .MuiRadio-root.Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
  border: 1px solid #000;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-disabled,
.theme-light .MuiRadio-root.Mui-disabled {
  color: #ccc;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label,
.theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label {
  color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label:before,
.theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label:before {
  border: 1px solid #ccc;
  background-color: #fff;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label .MuiSvgIcon-root,
.theme-light .MuiRadio-root.Mui-disabled .MuiIconButton-label .MuiSvgIcon-root {
  border: 1px solid #ccc;
}
html.theme-dark .theme-light .MuiRadio-root.Mui-disabled .radio-blank,
.theme-light .MuiRadio-root.Mui-disabled .radio-blank {
  border: 1px solid #ccc;
}

html.theme-light .theme-dark .MuiRadio-root,
.theme-dark .MuiRadio-root {
  color: #fff;
}
html.theme-light .theme-dark .MuiRadio-root .MuiIconButton-label:before,
.theme-dark .MuiRadio-root .MuiIconButton-label:before {
  background-color: #000;
}
html.theme-light .theme-dark .MuiRadio-root.Mui-focusVisible,
.theme-dark .MuiRadio-root.Mui-focusVisible {
  color: #fff;
}
html.theme-light .theme-dark .MuiRadio-root.Mui-checked,
.theme-dark .MuiRadio-root.Mui-checked {
  color: #fff;
}
html.theme-light .theme-dark .MuiRadio-root.error,
.theme-dark .MuiRadio-root.error {
  color: rgba(215, 96, 111, 0.5);
}
html.theme-light .theme-dark .MuiRadio-root .radio-blank,
.theme-dark .MuiRadio-root .radio-blank {
  border: 1px solid #fff;
}
.theme-light .MuiInputBase-root,
.theme-dark .MuiInputBase-root {
  border-radius: 0;
}
.theme-light .MuiInputBase-root fieldset,
.theme-dark .MuiInputBase-root fieldset {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
}
.theme-light .MuiSelect-root,
.theme-dark .MuiSelect-root {
  padding: 5px 15px;
  border-radius: 0;
}
.theme-light .MuiSelect-root input,
.theme-dark .MuiSelect-root input {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.MuiInputBase-sizeSmall .MuiSelect-select {
  padding-top: 4px;
  padding-bottom: 4px;
}

html.theme-dark .theme-light .MuiSelect-outlined input,
.theme-light .MuiSelect-outlined input {
  color: #000;
}
html.theme-dark .theme-light .MuiInputBase-root,
.theme-light .MuiInputBase-root {
  /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 
          0px 2px 2px 0px rgba(0,0,0,0.14), 
          0px 1px 5px 0px rgba(0,0,0,0.12);*/
  transition: box-shadow 0.3s, background-color 0.3s;
}
html.theme-dark .theme-light .MuiInputBase-root fieldset,
.theme-light .MuiInputBase-root fieldset {
  border-color: #000;
}

.MuiPopover-root .MuiCheckbox-root {
  margin-right: 10px;
}
.theme-light .MuiSlider-root .MuiSlider-rail,
.theme-dark .MuiSlider-root .MuiSlider-rail {
  height: 1px;
}

html.theme-dark .theme-light .MuiSlider-root,
.theme-light .MuiSlider-root {
  color: #000;
}
html.theme-dark .theme-light .MuiSlider-root .MuiSlider-thumb:hover,
.theme-light .MuiSlider-root .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1);
}
html.theme-dark .theme-light .MuiSlider-root .MuiSlider-mark,
.theme-light .MuiSlider-root .MuiSlider-mark {
  background-color: #fff;
  opacity: 1;
}
#root {
  padding: 0 20px;
}
html.dialog-mode #root {
  padding: 0;
}
@media screen and (min-width: 960px) {
  #root {
    padding: 0 40px;
  }
  html.dialog-mode #root {
    padding: 0;
  }
}
h1 {
  font-family: Mencken;
  font-size: 60px;
  font-style: italic;
  font-weight: 500;
  line-height: 60px;
}

h2 {
  font-family: Mencken;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  line-height: 26px;
}
.page {
  min-height: 50%;
  position: relative;
  flex: 1;
}
@media screen and (max-width: 600px) {
  html.w-side-dialog .page {
    display: none;
  }
}

.page-w-loading {
  position: relative;
}
.page-w-loading > .loading {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.page-w-loading > .loading .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-w-loading.page-w-loading-modal > .loading {
  pointer-events: all;
}

.app-loading {
  flex: 1;
  position: relative;
}
.app-loading .loading {
  top: 40%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

html.page-100vh #root {
  max-height: 100vh;
}
html.page-100vh .footer {
  display: none;
}
.date-range-selector {
  display: flex;
}

.date-range-selector-templates {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.date-range-selector-templates a {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  display: block;
  white-space: nowrap;
}

.date-range-selector-form {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #f0f0f0;
}

.date-range-selector-inputs {
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  padding-top: 20px;
}
.date-range-selector-inputs .MuiInputBase-root {
  margin-top: 0 !important;
}
.date-range-selector-inputs > label {
  font-family: HelveticaNeue;
  font-size: 14px;
  color: #000;
  text-align: center;
  display: block;
  white-space: nowrap;
  line-height: 40px;
  padding: 0 5px;
}

.date-range-selector-calendars {
  display: flex;
  flex-direction: row;
}

.date-range-selector-actions {
  margin-top: auto;
  display: flex;
}

.date-range-selector-selected {
  background-color: #062a3c;
  color: #fff;
}
.date-range-selector-selected .MuiButtonBase-root {
  background-color: #062a3c;
  color: #fff;
}
.date-range-selector-selected.date-range-selector-shadow {
  background-color: #999;
}
.date-range-selector-selected.date-range-selector-shadow .MuiButtonBase-root {
  background-color: #999;
}

.date-range-selector-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.date-range-selector-start .MuiButtonBase-root {
  background-color: transparent;
}

.date-range-selector-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.date-range-selector-end .MuiButtonBase-root {
  background-color: transparent !important;
}

.date-range-selector-range {
  background-color: #f0f0f0;
}
.date-range-selector-range .MuiButtonBase-root {
  background-color: transparent;
}

.date-range-selector-toggle {
  width: 40px;
  display: flex;
  border-left: 1px solid #f0f0f0;
}
.date-range-selector-toggle a {
  width: 100%;
  display: flex !important;
  transition: background-color 0.2s;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.date-range-selector-toggle a .icon {
  display: block;
  color: #999;
  transition: color 0.2s, transform 0.2s;
  transform: rotate(-90deg);
}
.date-range-selector-toggle a:hover {
  background-color: #f0f0f0;
}
.date-range-selector-toggle a:hover .icon {
  color: #000;
}
.date-range-selector-toggle.active a .icon {
  transform: rotate(90deg);
}
.empty-message {
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  font-family: HelveticaNeue;
  color: #555;
}
.footer {
  margin-top: 0;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
html.theme-dark .footer {
  height: 74px;
}
html.dialog-mode .footer {
  display: none;
}
.footer .footer-nav {
  height: 136px;
  width: 60%;
  display: none;
}
.footer .footer-nav.active {
  display: block;
}
.footer .footer-nav a {
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.footer .footer-nav a:hover {
  text-decoration: underline;
}
.footer .footer-nav a.active {
  text-decoration: underline;
}
.footer .footer-logo {
  height: 136px;
  width: 40%;
  color: #000;
  position: relative;
  display: none;
}
.footer .footer-logo.active {
  display: block;
}
.footer .footer-logo .icon-logo {
  width: 125px;
  height: 18px;
  position: absolute;
  bottom: 22px;
  right: 0;
}
@media screen and (min-width: 960px) {
  .footer .footer-logo .icon-logo {
    width: 166px;
    height: 24px;
  }
}
.footer .footer-copy {
  width: 100%;
  height: 64px;
  border-top: 1px solid #000;
  font-size: 12px;
  text-align: right;
  line-height: 12px;
  font-family: HelveticaNeue;
  color: rgba(0, 0, 0, 0.4);
  padding-top: 20px;
}
html.theme-dark .footer .footer-copy {
  border-color: rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.4);
}
@media screen and (min-width: 960px) {
  .footer .footer-copy {
    line-height: 64px;
    padding-top: 0px;
  }
}
:root {
  --header-height: 68px;
}

#header-toggle {
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
}
#header-toggle .icon {
  width: 21px;
  height: 21px;
}
@media screen and (min-width: 960px) {
  #header-toggle {
    display: none;
  }
}

#header-logo {
  margin-left: auto;
  margin-right: auto;
}
#header-logo .icon {
  width: 130px;
  height: 25px;
  color: #000;
}
@media screen and (min-width: 960px) {
  #header-logo .icon {
    width: 191px;
    height: 36px;
  }
}
@media screen and (min-width: 960px) {
  #header-logo {
    margin-right: 50px;
    margin-left: 0;
  }
}

#header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 68px;
  min-height: 68px;
  flex-direction: row;
}
html.dialog-mode #header {
  display: none;
}
#header .menu {
  display: none;
}
@media screen and (min-width: 960px) {
  #header .menu {
    display: flex;
    align-items: center;
  }
}
#header .menu .menu-item {
  height: 68px;
  line-height: 68px;
}
@media screen and (min-width: 960px) {
  #header .menu .menu-item {
    display: flex;
  }
}
#header .menu .menu-link {
  padding-right: 10px;
  padding-left: 10px;
  line-height: 68px;
}
@media screen and (min-width: 960px) {
  #header .menu .menu-link {
    display: flex;
    align-items: center;
    flex: 1;
  }
}
#header .menu .menu-link.with-badge {
  margin-right: 10px;
}
#header .menu .menu-link .badge {
  top: 20%;
  right: 0px;
}
#header .menu .menu-link .icon {
  margin-right: 10px;
}
#header .menu .menu-link:active:after, #header .menu .menu-link.active:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 1px;
  background-color: #000;
}
#header .menu .menu-link.disabled {
  opacity: 0.5;
}
#header .menu .menu-link[disabled] {
  opacity: 0.5;
}
#header .menu .menu-currencies .menu-link .icon,
#header .menu .menu-notifications .menu-link .icon {
  margin-right: 0;
}
#header .menu #header-username {
  padding-right: 0;
}
#header .menu .menu-item:hover .menu-link:after, #header .menu .menu-link:hover:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 1px;
  background-color: rgb(129, 123, 123);
}
#header .menu .menu-item:hover .menu-sub .menu-link:after {
  display: none;
}
#header .menu .menu-sub {
  padding: 10px 0;
}
#header .menu .menu-sub .menu-item {
  height: 40px;
  line-height: 40px;
}
#header .menu .menu-sub .menu-link, #header .menu .menu-sub .menu-label {
  line-height: 40px;
  padding: 0 20px;
}
#header .menu .menu-sub .menu-link.active:after, #header .menu .menu-sub .menu-label.active:after {
  display: none;
}
#header .menu .menu-sub .menu-item:hover a:after, #header .menu .menu-sub a:hover:after {
  display: none;
}
@media screen and (min-width: 960px) {
  #header .menu.right {
    margin-left: auto;
  }
}
#header .menu .avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
  margin-left: 10px;
}
#header .menu .loading svg {
  vertical-align: middle;
}
@media screen and (max-width: 960px) {
  #header .menu.header-user {
    display: flex;
  }
  #header .menu.header-user .menu-item.menu-user {
    display: none;
  }
}
#header .menu-notifications {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (min-width: 960px) {
  #header .menu-notifications {
    display: flex;
    align-items: stretch;
    position: relative;
  }
}
#header .app-header-bell {
  position: relative;
  display: flex;
  align-items: center;
}
#header .app-header-bell .badge {
  background-color: #000;
  color: #fff;
  display: block;
  position: absolute;
  top: -8px;
  left: 80%;
  border-radius: 50%;
  padding: 0 4px;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
}
#header .icon-bell {
  width: 24px;
  height: 24px;
}
#header .notifications-dialog {
  right: 0;
  left: auto;
  top: 100%;
}
#header .notifications-dialog .notification .avatar {
  font-size: 12px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
}
#header .menu-currencies {
  margin-left: 10px;
  display: flex;
  position: absolute;
  top: 0;
  right: 44px;
}
#header .menu-currencies > .menu-link {
  display: flex;
  align-items: center;
  height: 68px;
}
#header .menu-currencies > .menu-link span {
  margin-left: 5px;
}
#header .menu-currencies .icon {
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 960px) {
  #header .menu-currencies {
    display: block;
    position: relative;
    right: auto;
  }
}
@media screen and (max-width: 960px) {
  #header .menu-currencies .menu-sub {
    top: 68px;
    right: 0;
  }
}
#header.active .menu.header-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(var(--header-height) + 1px);
  left: 0;
  bottom: 0;
  max-width: 50%;
  min-width: 200px;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 10px;
}
#header.active .menu.header-nav .menu-item {
  height: 40px;
  line-height: 40px;
}
#header.active .menu.header-nav .menu-item .menu-link, #header.active .menu.header-nav .menu-item .menu-label {
  line-height: 40px;
}
#header.active .menu.header-nav .menu-sub {
  left: 100%;
  top: 0;
}
@media screen and (min-width: 960px) {
  #header.active .menu.header-nav {
    padding-right: 0;
  }
}
@media screen and (max-width: 960px) {
  #header.active .menu.header-user {
    display: block;
  }
  #header.active .menu.header-user .menu-sub {
    bottom: 0;
    top: auto;
  }
  #header.active .menu.header-user .menu-item {
    margin-left: auto;
  }
  #header.active .menu.header-user .menu-user .menu-sub {
    display: block;
    position: relative;
  }
  #header.active .menu.header-user .menu-item.menu-user {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    bottom: 50px;
    left: 0;
    height: auto;
    z-index: 5;
    width: 200px;
  }
  #header.active .menu.header-user .menu-item.menu-user > .menu-link {
    flex-direction: row-reverse;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #header.active .menu.header-user .menu-item.menu-user > .menu-link .avatar {
    margin-right: 10px;
  }
  #header.active .menu.header-user .menu-item.menu-user .menu-sub {
    position: static;
    box-shadow: none;
  }
  #header.active .menu.header-user .menu-item.menu-user .menu-sub .menu-item .menu-link {
    font-size: 12px;
  }
  #header.active .menu.header-user .menu-item.menu-currencies {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 10px;
    top: auto;
    z-index: 5;
  }
  #header.active .menu.header-user .menu-item.menu-currencies .menu-sub {
    left: 0;
    right: auto;
    bottom: 60px;
  }
}

#root:before {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  z-index: 1;
}
@media screen and (min-width: 960px) {
  #root:before {
    display: block;
  }
}
html.dialog-mode #root:before {
  display: none;
}

html.app-tools #root:before {
  top: 110px;
}
.menu {
  display: flex;
  align-items: center;
}
.menu .menu-sub {
  position: absolute;
  display: none;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  z-index: 10;
  top: 100%;
}
.menu .menu-item {
  position: relative;
}
.menu .menu-item .menu-link {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  display: block;
  white-space: nowrap;
}
.menu .menu-item .menu-link .icon {
  width: 16px;
  height: 16px;
}
.menu .menu-item .menu-link.disabled {
  color: #999;
}
.menu .menu-item .menu-link .badge {
  position: absolute;
  font-size: 10px;
  color: #fff;
  background-color: #000;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
}
.menu .menu-item .menu-link:hover + .menu-sub {
  display: block;
}
.menu .menu-item .menu-sub:hover {
  display: block;
}
.menu .menu-label {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #000;
  display: block;
  white-space: nowrap;
}
.menu .menu-separator {
  display: block;
  height: 1px;
  background-color: #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.menu .menu-sub {
  max-height: 500px;
  overflow-y: auto;
}
.menu .menu-sub .menu-link:hover {
  border-bottom: 0;
}
.menu .menu-sub .menu-link:hover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  left: -20px;
  background-color: #f0f0f0;
}
.menu .menu-sub .menu-link:active, .menu .menu-sub .menu-link.active {
  border-bottom: 0;
  background-color: #f0f0f0;
}
.menu .menu-sub .menu-link:active::before, .menu .menu-sub .menu-link.active::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  left: -20px;
  background-color: #ccc;
}
.signin-header {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 30px 0;
}
.toolbar .toolbar-section {
  display: flex;
}
.toolbar .toolbar-title {
  font-family: HelveticaNeue;
  font-size: 22px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.toolbar .spacer-full {
  margin-left: auto;
  margin-right: 0;
  flex: 1;
}
.toolbar .menu.right {
  margin-left: auto;
  margin-right: 0;
}
.toolbar .menu .menu-item {
  margin-left: 20px;
  position: relative;
}
.toolbar .menu .menu-item:first-child {
  margin-left: 0;
}
.toolbar .menu a {
  position: relative;
}
.toolbar .menu a.active {
  text-decoration: underline;
  text-underline-offset: 3px;
}
.toolbar .menu a .badge {
  top: -10px;
  right: -15px;
}
.toolbar .menu .menu-sub {
  padding: 10px 0;
  right: 0;
}
.toolbar .menu .menu-sub .menu-item {
  margin-left: 0;
}
.toolbar .menu .menu-sub a {
  line-height: 40px;
  padding: 0 20px;
}
.toolbar .menu .menu-sub a.active {
  border-bottom: 0;
}
.toolbar .MuiTextField-root label {
  position: absolute;
  top: 12px;
  left: 10px;
  color: rgba(0, 0, 0, 0.6);
}
.toolbar .MuiTextField-root label.Mui-focused {
  display: none;
}
.toolbar .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}
.toolbar h1 {
  font-family: HelveticaNeue;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  font-style: normal;
}
.toolbar span.text {
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  color: #000;
  margin-left: 30px;
  white-space: nowrap;
}
.user-search {
  position: relative;
}
.user-search .loading {
  position: absolute;
  top: 31px;
  right: 10px;
}

.dropdown-user-search {
  width: 100%;
  margin-top: 5px;
  padding: 5px 0 5px 0;
  max-height: 300px;
  overflow: auto;
}
.dropdown-user-search a {
  display: block;
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 5px 20px;
}
.dropdown-user-search a:hover {
  background-color: #fafafa;
}
.dropdown-user-search a b {
  font-weight: 600;
}

.access-tree ul ul {
  margin-left: 30px;
}
.access-tree ul {
  margin-top: 2px;
}
.access-tree li {
  padding: 2px 0;
}
.access-tree .loading {
  margin-right: 10px;
}

.access-tree-invalid {
  margin-top: 20px;
}
.fri-payment-dialog {
  min-width: 100%;
  max-height: 100vh;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%) scale(0.3);
          transform: translate(-50%, -50%) scale(0.3);
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 960px) {
  .fri-payment-dialog {
    min-width: 0;
    width: 1024px;
    min-height: 700px;
    max-height: 90vh;
    flex-direction: column;
    padding: 30px 40px;
    padding-bottom: 0;
  }
}
.fri-payment-dialog.a-zoom-in {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) scale(0.3);
          transform: translate(-50%, -50%) scale(0.3);
}
.fri-payment-dialog.a-zoom-in.a-zoom-in-active {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.fri-payment-dialog.a-zoom-out {
  display: flex;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.fri-payment-dialog.a-zoom-out.a-zoom-out-active {
  -webkit-transform: translate(-50%, -50%) scale(0.3);
          transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
}
.fri-payment-dialog.active {
  display: flex;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.fri-payment-dialog a.close {
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;
  z-index: 1;
}

.fri-payment-dialog-body {
  display: flex;
  flex: 1;
}
.fri-payment-dialog-body .user-selector {
  width: 30%;
  max-height: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
}
.fri-payment-dialog-body .MuiPickersStaticWrapper-staticWrapperRoot {
  margin-left: 20px;
  background-color: #f0f0f0;
}
.fri-payment-dialog-body .MuiPickersStaticWrapper-staticWrapperRoot .MuiToolbar-root {
  background-color: #f0f0f0;
}
.fri-payment-dialog-body .MuiPickersStaticWrapper-staticWrapperRoot .MuiToolbar-root .MuiPickersToolbarText-toolbarTxt {
  color: #000;
}

.fri-payment-dialog-actions {
  padding: 20px 0;
  margin-left: auto;
  margin-right: auto;
}

.fri-payment-dialog-form {
  margin-left: 20px;
  flex: 1;
}

.fri-payment-dialog-amount {
  display: flex;
}
.user-group-toggle {
  margin-bottom: 20px;
}

.tag-selector-v2.expanded.user-group-toggle-selector .tag-selector-body {
  display: flex;
  flex-direction: column;
}
.tag-selector-v2.expanded.user-group-toggle-selector .tag-selector-body label {
  font-size: 14px;
  font-family: HelveticaNeue;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.tag-selector-v2.expanded.user-group-toggle-selector .tag-selector-body .MuiToggleButton-root {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.page-accounting-commissions .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-accounting-commissions > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-accounting-commissions .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}

.page-accounting-extra-amount {
  width: 200px;
}
.page-accounting-extra-amount > div {
  display: flex;
}
.page-accounting-extra-amount select {
  height: 30px;
}
.page-accounting-payments .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-accounting-payments > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.revolut-draft-dialog {
  min-width: 500px;
}
.revolut-draft-dialog .MuiFormControl-root {
  margin-top: 20px;
}
.revolut-draft-dialog .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-accounting-settings .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-accounting-settings .grid td {
  width: 33%;
}
.page-catalogue-categories {
  position: relative;
}
.page-catalogue-categories > .page-catalogue-categories-form {
  margin-bottom: 20px;
}
.page-catalogue-categories > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.page-catalogue-categories-list {
  width: 100%;
}
.page-catalogue-categories-list li {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ccc;
  flex-wrap: wrap;
}
.page-catalogue-categories-list li:last-child {
  border-bottom: 0;
}
.page-catalogue-categories-list li > ul {
  border-top: 1px solid #ccc;
  margin-left: 30px;
}
.page-catalogue-categories-list li > .page-catalogue-categories-form {
  padding: 10px;
}
.page-catalogue-categories-list h4 {
  font-size: 24px;
  font-weight: 500;
}
.page-catalogue-categories-list p {
  font-size: 20px;
  font-family: HelveticaNeue;
  color: #666;
}
.page-catalogue-categories-list .actions {
  display: flex;
  align-items: flex-start;
}
.page-catalogue-categories-list .actions a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.page-catalogue-categories-list .actions .icon {
  width: 20px;
  height: 20px;
  color: #000;
}

.page-catalogue-categories-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex: auto;
}
.page-catalogue-categories-info div[id] {
  flex: auto;
  padding: 10px;
  background-color: #fff;
}
.page-catalogue-categories-info h4 a {
  color: #000;
}
.page-catalogue-categories-info h4 a .icon {
  margin-left: 10px;
}
.page-catalogue-categories-info h4 a.more {
  margin-left: 10px;
  font-size: 30px;
  vertical-align: text-top;
  line-height: 0.65;
  color: #000;
}
.page-catalogue-categories-info h4 a.more .icon {
  margin-left: 0;
}
.page-catalogue-categories-info.in-menu h4 {
  text-decoration: underline;
}
.page-catalogue-categories-info.drop-active {
  position: relative;
}
.page-catalogue-categories-info.drop-active:before {
  position: absolute;
  content: "";
  top: -1px;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #000;
}
.page-catalogue-categories-info.drop-active-inside {
  position: relative;
}
.page-catalogue-categories-info.drop-active-inside:before {
  position: absolute;
  content: "";
  top: 50%;
  height: 1px;
  width: 20px;
  left: -20px;
  background-color: #000;
}
.page-catalogue-categories-info.active h4 a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-catalogue-categories-info .catalogue-product-list-item {
  width: 16.6666666667% !important;
}
.page-catalogue-categories-info .pagination {
  margin-top: 10px;
}
.page-catalogue-categories-info .page-catalogue-feed-colors {
  margin-top: 0;
  padding-left: 10px;
}

.page-catalogue-categories-form {
  display: flex;
  align-content: stretch;
  width: 100%;
}
.page-catalogue-categories-form .MuiTextField-root {
  margin-right: 5px;
  flex: 1;
}
.page-catalogue-categories-form .MuiInputBase-root {
  margin-top: 0 !important;
}
.page-catalogue-categories-form .MuiFormControlLabel-root {
  margin-left: 15px;
  margin-right: 15px;
}

.page-catalogue-categories-info + .page-catalogue-categories-form {
  margin-left: 30px;
  border-top: 1px solid #ccc;
}

.page-catalogue-categories-info .catalogue-products {
  width: 100%;
  padding-left: 10px;
}
.page-catalogue-categories-info .catalogue-products .catalogue-products-item {
  width: 16.6666666667%;
}
.page-catalogue-categories-info .pagination {
  margin-bottom: 20px;
}
.page-categories-test {
  padding: 1rem;
}
.page-categories-test .images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}
.page-categories-test > p,
.page-categories-test a {
  display: block;
  font-size: 14px;
  line-height: 1.1;
  font-family: HelveticaNeue;
}
.page-categories-test .image {
  width: 150px;
  border: 1px solid #f0f0f0;
}
.page-categories-test .image img {
  width: 100%;
}
.page-categories-test .image p {
  font-size: 14px;
  line-height: 1.1;
  font-family: HelveticaNeue;
}
.page-categories-test .fail {
  color: red;
}
.page-categories-test .match {
  color: green;
}
.page-catalogue-category-leafs {
  margin-left: auto;
}
.page-catalogue-category-leafs .page-catalogue-category-leaf-assign {
  margin-top: 20px;
}
.page-catalogue-category-leafs .page-catalogue-category-leaf-assign:first-child {
  margin-top: 0;
}

.page-catalogue-category-leaf-assign {
  margin-left: auto;
}
.page-catalogue-category-leaf-assign .tag-list {
  margin-top: 10px;
}
.page-catalogue-category-leaf-assign h4 {
  margin-right: 10px;
  line-height: 40px;
}

.page-catalogue-category-char-select {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}
.page-catalogue-category-char-select a {
  font-family: HelveticaNeue;
  font-size: 14px;
  color: #000;
  line-height: 14px;
}
.page-catalogue-category-char-select a span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  margin-right: 5px;
}
.page-catalogue-category-char-select a .icon {
  width: 14px;
  height: 14px;
  color: #000;
  margin-top: 2px;
  margin-left: 2px;
}
.page-catalogue-category-char-select .tag-list {
  flex: 1;
  width: 100%;
}

.page-catalogue-category-leaf-buttons {
  margin-top: 20px;
}
.page-catalogue-characteristics {
  position: relative;
}
.page-catalogue-characteristics > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.page-catalogue-characteristic {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.page-catalogue-characteristic h4 {
  font-size: 24px;
  font-weight: 500;
}
.page-catalogue-characteristic h4 a {
  margin-left: 10px;
}
.page-catalogue-characteristic p {
  font-size: 20px;
  font-family: HelveticaNeue;
  color: #666;
}
.page-catalogue-characteristic .actions a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.page-catalogue-characteristic .actions .icon {
  width: 20px;
  height: 20px;
  color: #000;
}
.page-catalogue-characteristic.active h4 a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-catalogue-characteristic .pagination {
  margin-top: 10px;
}

.page-catalogue-characteristic-form {
  display: flex;
  align-content: stretch;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.page-catalogue-characteristic-form .MuiTextField-root {
  margin-right: 5px;
  flex: 1;
}
.page-catalogue-characteristic-form .MuiInputBase-root {
  margin-top: 0 !important;
}
.page-catalogue-color-queue {
  position: relative;
}
.page-catalogue-color-queue h2 {
  margin-top: 30px;
  font-style: normal;
}
.page-catalogue-color-queue .toolbar label {
  font-size: 18px;
  margin-left: 10px;
  line-height: 18px;
  margin-right: 20px;
}
.page-catalogue-color-queue .toolbar .MuiCheckbox-root {
  margin-left: 10px;
}
.page-catalogue-color-queue .toolbar + .toolbar {
  padding-top: 0;
}
.page-catalogue-color-queue > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-catalogue-color-queue .page-catalogue-color .actions {
  margin-left: auto;
}
.page-catalogue-color-queue .page-catalogue-color .MuiCheckbox-root {
  margin-right: 20px;
}
.page-catalogue-color-queue .page-catalogue-color .page-catalogue-color-form {
  margin-left: auto;
  border-bottom: 0;
  padding: 0;
}
.page-catalogue-color-queue .tag-selector {
  width: 300px;
}
.page-catalogue-color-queue .pagination {
  margin-top: 20px;
}

.page-catalogue-color-queue-create {
  justify-content: flex-start;
}
.page-catalogue-color-queue-create .page-catalogue-color-form {
  margin-left: auto;
  border-bottom: 0;
  padding: 0;
}

.page-catalogue-color-assign {
  display: flex;
  margin-left: auto;
}
.page-catalogue-color-assign .MuiAutocomplete-root {
  margin-right: 10px;
}
.page-catalogue-color-assign .MuiInputBase-root {
  margin-top: 0 !important;
  min-height: 40px;
}
.page-catalogue-colors {
  position: relative;
}
.page-catalogue-colors h2 {
  margin-top: 30px;
  font-style: normal;
}
.page-catalogue-colors > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.page-catalogue-color {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.page-catalogue-color h4 {
  font-size: 24px;
  font-weight: 500;
}
.page-catalogue-color h4 a {
  margin-left: 10px;
}
.page-catalogue-color .name {
  display: flex;
}
.page-catalogue-color .name a {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.page-catalogue-color .name a .icon {
  margin-left: 7px;
  margin-top: 7px;
}
.page-catalogue-color.active .name a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-catalogue-color p {
  font-size: 20px;
  font-family: HelveticaNeue;
  color: #666;
}
.page-catalogue-color .actions a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.page-catalogue-color .actions .icon {
  width: 20px;
  height: 20px;
  color: #000;
}
.page-catalogue-color.active h4 a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-catalogue-color .pagination {
  margin-top: 10px;
}

.page-catalogue-color-form {
  display: flex;
  align-content: stretch;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.page-catalogue-color-form .MuiTextField-root {
  margin-right: 5px;
  flex: 1;
}
.page-catalogue-color-form .MuiInputBase-root {
  margin-top: 0 !important;
}

.page-catalogue-queue-form-centered {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}
.page-catalogue-queue-form-centered .MuiTextField-root {
  margin-right: 5px;
  flex: 1;
}
.page-catalogue-queue-form-centered .MuiInputBase-root {
  margin-top: 0 !important;
}

.page-catalogue-feed-colors {
  margin-top: 10px;
  width: 100%;
  max-height: 120px;
  overflow: auto;
}
.page-catalogue-control-panel {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.page-catalogue-control-panel-section h2 {
  margin: 0;
  font-size: 0.875rem;
  font-family: HelveticaNeue;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.5rem;
}
.page-catalogue-control-panel-section h2 span {
  font-size: 0.75rem;
  color: #666;
}

.page-catalogue-control-panel-failed {
  background-color: #cc4444;
  color: #fff;
}

.page-catalogue-control-panel-running {
  background-color: #9999cc;
  color: #fff;
}

.page-catalogue-control-panel-pending {
  background-color: #f0f0f0;
}

.page-catalogue-control-panel-property-name {
  vertical-align: middle !important;
}

.page-catalogue-control-panel-disabled td {
  background-color: #f5f5f5;
  color: #888;
}
.page-designer-exclusion .MuiAutocomplete-root {
  min-width: 300px;
}
.page-designer-exclusion .grid tr {
  cursor: move;
}
.page-designer-exclusion .grid tr.droppable-active {
  opacity: 0.2;
}
.page-designer-top .MuiAutocomplete-root {
  min-width: 300px;
}
.page-designer-top .grid tr {
  cursor: move;
}
.page-designer-top .grid tr.droppable-active {
  opacity: 0.2;
}
.page-catalogue-ignored h2 {
  margin-top: 30px;
  font-style: normal;
}
.page-catalogue-ignored .pagination {
  margin-top: 20px;
}

.page-catalogue-ignore-section {
  padding: 20px 0;
}
.page-catalogue-ignore-section h3 {
  font-size: 20px;
  font-style: normal;
  color: #000;
  margin-bottom: 10px;
}
.page-catalogue-ignore-section .MuiTextField-root {
  margin-bottom: 20px;
}
.page-catalogue-materials {
  position: relative;
}
.page-catalogue-materials > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.page-catalogue-material {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.page-catalogue-material h4 {
  font-size: 24px;
  font-weight: 500;
}
.page-catalogue-material h4 a {
  margin-left: 10px;
}
.page-catalogue-material p {
  font-size: 20px;
  font-family: HelveticaNeue;
  color: #666;
}
.page-catalogue-material .actions a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.page-catalogue-material .actions .icon {
  width: 20px;
  height: 20px;
  color: #000;
}
.page-catalogue-material.active h4 a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-catalogue-material .pagination {
  margin-top: 10px;
}

.page-catalogue-material-form {
  display: flex;
  align-content: stretch;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.page-catalogue-material-form .MuiTextField-root {
  margin-right: 5px;
  flex: 1;
}
.page-catalogue-material-form .MuiInputBase-root {
  margin-top: 0 !important;
}
.page-catalogue-files .grid {
  margin-top: 30px;
}
.page-catalogue-files .grid a {
  color: #000;
  text-decoration: underline;
}

.page-catalogue-search .toolbar .MuiButton-root {
  margin-left: 15px;
}
.page-catalogue-search .grid th:first-child,
.page-catalogue-search .grid td:first-child {
  width: 1px;
}
.page-catalogue-search .grid td:first-child {
  padding: 0;
}
.page-catalogue-search .grid td:first-child img {
  max-width: 100px;
}
.page-catalogue-search .pagination {
  margin-top: 15px;
}

.catalogue-products {
  /*display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100% + 10px);
  margin-left: -10px;
  margin-right: -10px;
  position: relative;*/
}
.catalogue-products::after {
  content: "";
  flex: auto;
}

.catalogue-products-empty {
  font-size: 16px;
  font-family: HelveticaNeue;
}

.page .catalogue-products {
  width: 100%;
}
.page .catalogue-products-item {
  width: 12.5%;
}
.page .catalogue-products-empty {
  margin-left: 10px;
  margin-top: 10px;
}
.page-chats td a {
  color: #000;
  text-decoration: underline;
}
.page-clicks .pagination {
  margin-top: 20px;
}
.page-clicks > h2 {
  font-style: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}
.page-clicks .empty {
  font-size: 16px;
  font-family: HelveticaNeue;
  text-align: center;
  margin-top: 20px;
}
.page-clicks .toolbar .icon {
  width: 16px;
  height: 16px;
}
.page-clicks .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-clicks a {
  cursor: pointer;
}
.page-clicks .user-handle a.name {
  color: #000;
}
.page-clicks > .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-clicks .date-range-selector {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  top: 30px;
}
.page-clicks .look-card-footer {
  font-family: HelveticaNeue;
  font-size: 14px;
}
.page-clicks .look-card-footer b {
  margin-left: 10px;
}
.page-clicks .grid td.data-col {
  font-size: 16px;
}

.dialog-click-info h4 {
  font-family: Mencken;
  font-size: 20px;
  margin-top: 20px;
}
.dialog-click-info .side-dialog-body > a {
  font-family: HelveticaNeue;
  color: #000;
  text-decoration: underline;
  font-size: 14px;
  display: inline-block !important;
}
.dialog-click-info .MuiButton-root {
  margin-top: 10px;
  margin-right: 10px;
}
.dialog-click-info p, .dialog-click-info span {
  font-family: HelveticaNeue;
  font-size: 14px;
}

.page-clicks-layout {
  display: flex;
  align-items: flex-start;
}
.page-clicks-layout .left {
  width: 20%;
  margin-right: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.page-clicks-layout .right {
  width: 80%;
}

.page-clicks-expanded-row .MuiButton-root {
  margin-right: 10px;
}

.page-clicks-expanded-toolbar {
  margin-top: 10px;
  display: flex;
}
.page-clicks-expanded-toolbar:first-child {
  margin-top: 0;
}
.page-clicks-expanded-toolbar .spacer {
  display: block;
  margin-left: auto;
}

.page-clicks-expanded-details {
  overflow-wrap: break-word;
  word-break: break-all;
}

.page-clicks-details-name {
  width: 20%;
  white-space: nowrap;
}

.page-clicks-details-value {
  width: 80%;
  word-break: break-all;
}
.page-commissions .pagination {
  margin-top: 20px;
}
.page-commissions > h2 {
  font-style: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}
.page-commissions .empty {
  font-size: 16px;
  font-family: HelveticaNeue;
  text-align: center;
  margin-top: 20px;
}
.page-commissions .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-commissions .user-handle a.name {
  color: #000;
}
.page-commissions > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-commissions .stats {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.page-commissions .stats li {
  width: 50%;
  height: 180px;
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}
.page-commissions .stats li:before {
  content: "";
  position: absolute;
  display: block;
  top: 6px;
  left: 6px;
  bottom: 6px;
  right: 6px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 960px) {
  .page-commissions .stats li {
    width: 16.6666666667%;
  }
}
.page-commissions .stats li b {
  display: block;
  font-family: Mencken;
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.5px;
}
.page-commissions .stats li span {
  display: block;
  margin-top: 20px;
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: 600;
}
.page-commissions .date-range-selector {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
  top: 30px;
}
.page-commissions .look-card-footer {
  font-family: HelveticaNeue;
  font-size: 14px;
}
.page-commissions .look-card-footer b {
  margin-left: 10px;
}
.page-commissions .page-commissions-grid-wrapper {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  overflow-x: auto;
}
.page-commissions .grid td.data-col {
  font-size: 16px;
}
.page-commissions .grid td.expanded-col pre {
  font-size: 12px;
}
.page-commissions .grid tr.status-pending td:first-child, .page-commissions .grid tr.status-ok td:first-child, .page-commissions .grid tr.status-notok td:first-child, .page-commissions .grid tr.status-okeish td:first-child {
  position: relative;
}
.page-commissions .grid tr.status-pending td:first-child:before, .page-commissions .grid tr.status-ok td:first-child:before, .page-commissions .grid tr.status-notok td:first-child:before, .page-commissions .grid tr.status-okeish td:first-child:before {
  z-index: 0;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  transition: width 0.2s;
}
.page-commissions .grid tr.status-pending td:first-child:before {
  background-color: #ccc;
}
.page-commissions .grid tr.status-ok td:first-child:before {
  background-color: rgba(0, 128, 0, 0.6);
}
.page-commissions .grid tr.status-notok td:first-child:before {
  background-color: rgba(255, 0, 0, 0.6);
}
.page-commissions .grid tr.status-okeish td:first-child:before {
  background-color: rgba(173, 255, 47, 0.6);
}
.page-commissions .grid tr:hover td.expandable-col:before, .page-commissions .grid tr.expanded td.expandable-col:before {
  width: 5px;
}
.page-commissions .approved-amount {
  color: #666;
  margin-left: 5px;
}

.page-commission-details-products {
  margin-top: 10px;
  width: auto;
}
.page-commission-details-products:first-child {
  margin-top: 0;
}

.page-commission-details-table {
  margin-top: 10px;
}
.page-commission-details-table:first-child {
  margin-top: 0;
}

.page-commission-details-toolbar {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.page-commission-details-toolbar:first-child {
  margin-top: 0;
}
.page-commission-details-toolbar .MuiButton-root {
  margin-right: 10px;
}
.page-commission-details-toolbar .spacer {
  display: block;
  margin-left: auto;
}
.page-commission-details-toolbar a {
  color: #000;
  text-decoration: underline;
  margin-right: 10px;
}

.page-commission-details-raw {
  border: 1px solid #ccc;
  max-height: 300px;
  overflow: auto;
  margin-top: 10px;
  padding: 0 10px;
}
.page-commission-details-raw:first-child {
  margin-top: 0;
}

.page-commissions-layout {
  display: flex;
  align-items: flex-start;
}
.page-commissions-layout .left {
  width: 15%;
  margin-right: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.page-commissions-layout .right {
  width: 85%;
}
.page-commissions-layout .right h2 {
  font-style: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .dialog-commission-history.active {
    width: 700px;
  }
}
.page-connections .pagination {
  margin-top: 20px;
}
.page-contacts .toolbar h1 {
  margin-left: auto;
  margin-right: auto;
}
.page-contacts .user-contacts {
  margin-top: 50px;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.page-content {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.page-content .MuiTreeView-root {
  width: 20%;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #ccc;
}
.page-content .editor {
  flex: 1;
}
.page-content .editor h3 {
  font-size: 22px;
  font-weight: 400;
}
.page-content .editor-footer {
  margin-top: 20px;
}
.page-content .editor-footer button {
  margin-right: 10px;
}
.page-content .editor-status {
  font-size: 14px;
  margin-top: 5px;
}
.page-content .editor-status span {
  color: #990000;
  margin-right: 10px;
}
.page-content .dropzone {
  min-height: 200px;
  max-width: 400px;
  border: 1px dotted #ccc;
}
.page-content .dropzone p {
  font-size: 14px;
  text-align: center;
}
.page-content .dropzone img {
  width: 100%;
}
.page-dashboard {
  padding-top: 60px;
}
.page-dashboard .stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.page-dashboard .stats li {
  width: 50%;
  height: 180px;
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}
.page-dashboard .stats li:before {
  content: "";
  position: absolute;
  display: block;
  top: 6px;
  left: 6px;
  bottom: 6px;
  right: 6px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 960px) {
  .page-dashboard .stats li {
    width: 16.6666666667%;
  }
}
.page-dashboard .stats li b {
  display: block;
  font-family: Mencken;
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.5px;
}
.page-dashboard .stats li span {
  display: block;
  margin-top: 20px;
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: 600;
}
.page-report-fris .pagination {
  margin-top: 20px;
}
.page-report-fris > h2 {
  font-style: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}
.page-report-fris .empty {
  font-size: 16px;
  font-family: HelveticaNeue;
  text-align: center;
  margin-top: 20px;
}
.page-report-fris .icon {
  width: 16px;
  height: 16px;
  color: #888;
}
.page-report-fris .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-report-fris a {
  cursor: pointer;
}
.page-report-fris .user-handle a.name {
  color: #000;
}
.page-report-fris > .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-report-fris td span {
  color: #999;
}
.page-report-fris td .user-handle span {
  color: #000;
}
.page-report-fris .grid {
  /*th, td {
      padding: 18px 10px;
  }*/
}
.page-report-fris .grid td.data-col {
  font-size: 14px;
}
.page-report-fris .grid th {
  padding: 18px 0;
}
.page-report-fris .grid th:first-child {
  padding-left: 18px;
}
.page-report-fris .grid th:last-child {
  padding-left: 18px;
}

.page-report-fris-layout {
  display: flex;
  align-items: flex-start;
}
.page-report-fris-layout .left {
  width: 20%;
  margin-right: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.page-report-fris-layout .right {
  width: 80%;
}

.page-reports-fri-domains td {
  padding: 10px;
}
.page-invitations {
  /*.toolbar {
      justify-content: flex-start;

      .MuiTextField-root {
          margin-right: 10px;
      }
  }

  .grid {
      th, td {
          padding: 5px;
      }
  }

  td, th {
      white-space: nowrap;
  }

  td:nth-child(2),
  th:nth-child(2) {
      flex: 1;
      width: 100%;
  }

  */
}
.page-invitations .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.page-invitations .pagination {
  margin-top: 20px;
}
.page-marketing-banner {
  flex-wrap: nowrap;
}
.page-marketing-banner .actions {
  display: flex;
}

.page-marketing-banner-form {
  align-items: flex-end;
}
.page-marketing-banner-form .MuiFormControlLabel-root {
  margin-right: 5px;
  margin-bottom: 10px;
}

.page-marketing-banner-content p {
  font-size: 14px;
}
.page-marketing-banner-content h4 {
  display: flex;
  align-items: center;
  line-height: 24px;
  gap: 8px;
  margin-bottom: 4px;
}

.page-marketing-banner-status {
  height: 10px;
  width: 10px;
  background-color: #ce0404;
  border-radius: 50%;
  display: inline-block;
  margin-top: 4px;
  flex-shrink: 0;
}
.page-marketing-banner-status.active {
  background-color: green;
}

.page-marketing-banner-preview {
  height: 50px;
  padding: 1rem;
  background: #F3F0EB;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 1rem;
}
.page-marketing-banner-preview p {
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 95%;
  text-align: center;
}
.page-marketing-banner-preview svg {
  height: 20px;
  position: absolute;
  width: 20px;
  right: 15px;
  margin-left: 1rem;
}

.page-marketing-banner-preview-width {
  margin-top: 0.5rem !important;
}
.page-people .toolbar {
  justify-content: flex-start;
}
.page-people .toolbar .MuiButton-root {
  margin-right: 10px;
}
.page-people .toolbar .MuiButton-root:last-child {
  margin-right: 0;
  margin-left: 10px;
}
.page-people .toolbar .MuiTextField-root {
  width: 300px;
}
.page-people .grid td:first-child, .page-people .grid th:first-child {
  width: 10px;
}
.page-people .grid tbody tr {
  cursor: pointer;
}
.page-people .grid .avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
  margin-right: 5px;
  font-size: 12px;
}
.page-people .grid .page-people-access-col {
  max-width: 300px;
}
.page-people .pagination {
  margin-top: 30px;
}

.dialog-user .image {
  width: 100%;
  padding-bottom: 100%;
  border: 1px dotted #000;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}
.dialog-user .tag-list {
  margin-bottom: 30px;
}
.dialog-user .user-handle {
  justify-content: space-between;
  margin-bottom: 25px;
}
.dialog-user .user-handle .avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.dialog-user .user-handle .name {
  font-family: Mencken;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}
.dialog-user .user-handle .email {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}
.dialog-user .user-handle p {
  text-align: left;
}
.dialog-user h4 {
  font-family: Mencken;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
}
.dialog-user .phone {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 25px;
  margin-top: 20px;
}
.dialog-user .links {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 25px;
}
.dialog-user .links a {
  color: #000;
  text-decoration: underline;
}
.dialog-user .created {
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
}
.dialog-user .actions {
  margin-top: 20px;
}
.dialog-user .actions button {
  margin-right: 15px;
}
.dialog-user .default-shopper {
  margin-top: 20px;
}
.dialog-user .user-details-retailers {
  margin-top: 10px;
}
.dialog-user .user-details-retailer {
  width: 100%;
}
.dialog-user .user-details-retailer-all {
  margin-bottom: 10px;
}

.dialog-create-user .MuiTextField-root {
  margin-top: 15px;
}
.dialog-create-user .MuiTextField-root:first-child {
  margin-top: 0;
}
.dialog-create-user .actions {
  margin-top: 20px;
}
.dialog-create-user .actions button {
  margin-right: 15px;
}
.dialog-create-user h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.dialog-invitation h4 {
  font-family: Mencken;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
}
.dialog-invitation select {
  padding: 10px;
  margin-bottom: 20px;
}
.dialog-invitation label {
  font-family: HelveticaNeue;
  font-size: 16px;
}
.dialog-invitation .user-details-retailer {
  width: 100%;
}
.dialog-invitation .MuiButton-root {
  margin-top: 20px;
}
.dialog-invitation .user-handle {
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.dialog-invitation .user-handle .avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.dialog-invitation .user-handle .name {
  font-family: Mencken;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}
.dialog-invitation .user-handle .email {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}
.dialog-invitation .user-handle p {
  margin-left: 10px;
  text-align: left;
}
.dialog-invitation .MuiButton-root {
  margin-right: 10px;
}
.dialog-invitation .dialog-invitation-actions {
  display: flex;
}
.page-performance-timing select {
  height: 30px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.page-products {
  position: relative;
}
.page-products .toolbar {
  flex-wrap: wrap;
  align-items: flex-start;
}
.page-products .toolbar > .MuiTextField-root {
  margin-right: 10px;
}
.page-products .toolbar > .MuiTextField-root input {
  padding-top: 11px;
  padding-bottom: 10px;
}
.page-products .toolbar-section {
  width: 32%;
  flex-direction: column;
}
.page-products .toolbar-section .tag-selector {
  width: 100%;
  margin-bottom: 5px;
}
.page-products .toolbar-section .tag-selector:last-child {
  margin-bottom: 0;
}
.page-products .toolbar-section .MuiTextField-root {
  width: 100%;
}
.page-products .toolbar-section > .MuiTextField-root {
  margin-bottom: 5px;
}
.page-products .toolbar-section > form {
  margin-bottom: 5px;
}
.page-products .tag-selector {
  margin-right: 10px;
  min-width: 200px;
}
.page-products .tag-selector:last-child {
  margin-right: auto;
}
.page-products .pagination {
  margin-top: 30px;
}
.page-products .catalogue-products .catalogue-products-item {
  width: 12.5%;
}

.dialog-product p {
  line-height: 1.5;
  font-size: 14px;
  font-family: HelveticaNeue;
}
.dialog-product p b {
  font-weight: 700;
}
.page-pse-applications-table td {
  vertical-align: middle;
}
.page-pse-applications-table .MuiIconButton-root {
  margin-left: auto;
  display: block;
}
.page-pse-applications-table .MuiIconButton-root .icon {
  width: 12px;
  height: 12px;
}

.dialog-pse-application h4 {
  font-family: Mencken;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
}
.dialog-pse-application label {
  font-family: HelveticaNeue;
  font-size: 16px;
}
.dialog-pse-application .user-details-retailer {
  width: 100%;
}
.dialog-pse-application .MuiButton-root {
  margin-top: 20px;
}
.dialog-pse-application .user-handle {
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.dialog-pse-application .user-handle .avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
}
.dialog-pse-application .user-handle .name {
  font-family: Mencken;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}
.dialog-pse-application .user-handle .email {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}
.dialog-pse-application .user-handle p {
  margin-left: 10px;
  text-align: left;
}
.dialog-pse-application .MuiButton-root {
  margin-right: 10px;
}
.dialog-pse-application .MuiButton-root .icon {
  width: 12px;
  height: 12px;
}
.dialog-pse-application .dialog-invitation-actions {
  display: flex;
}
.page-removebg p {
  text-align: center;
  font-size: 20px;
}
.page-raw-report > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.page-raw-report .icon-download {
  margin-right: 5px;
}
.page-raw-report .menu-item .menu-download {
  display: flex;
  align-items: center;
}
.page-raw-report .grid-container {
  overflow: auto;
  border-right: 1px solid #d8d8d8;
}
.page-raw-report .grid-loading {
  opacity: 0.5;
}
.page-raw-report .date-range-selector {
  background-color: #fff;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 10;
  border: 1px solid #d8d8d8;
}
.page-search-history .grid {
  margin-top: 30px;
}
.page-search-history .grid td:first-child, .page-search-history .grid th:first-child {
  width: 10px;
}
.page-search-history .pagination {
  margin-top: 30px;
}
.page-search-test h3 {
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.page-search-test select {
  max-width: 120px;
  height: 40px;
}
.page-search-test label {
  font-size: 12px;
  font-family: HelveticaNeue;
}
.page-search-test .results {
  display: flex;
  gap: 10px;
}
.page-search-test .products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 70%;
  align-content: flex-start;
}
.page-search-test .result-stats {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-search-test .code-editor {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.page-search-test .code-editor > p {
  font-size: 14px;
  line-height: 16px;
  font-family: HelveticaNeue;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  color: #666;
}
.page-search-test .query-classification pre {
  font-size: 14px;
  line-height: 16px;
  font-family: HelveticaNeue;
  white-space: normal;
}
.page-search-test .product {
  width: 200px;
  border: 1px solid #f0f0f0;
  padding: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.page-search-test .product img {
  max-width: 100%;
  display: block;
  max-height: 200px;
  margin-left: auto;
  margin-right: auto;
}
.page-search-test .product .retailer,
.page-search-test .product .brand,
.page-search-test .product .name,
.page-search-test .product .description,
.page-search-test .product .score,
.page-search-test .product .price,
.page-search-test .product .id {
  font-size: 14px;
  line-height: 16px;
  font-family: HelveticaNeue;
  margin: 0;
  padding: 0;
}
.page-search-test .product .id {
  font-size: 10px;
  line-height: 10px;
}
.page-search-test .product .score {
  font-size: 10px;
  line-height: 10px;
}
.page-search-test .product .retailer {
  font-weight: bold;
  text-transform: uppercase;
}
.page-search-test .product .name {
  font-weight: bold;
}
.page-search-test .product .description {
  color: #666;
}
.page-search-test .image-rankings {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.page-search-test .image-rankings .image-row {
  width: 100px;
  border: 1px solid #ccc;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-search-test .image-rankings .image-row img {
  width: 100%;
}
.page-search-test .image-rankings .image-row .score {
  font-size: 12px;
  font-family: HelveticaNeue;
}
.page-search-test .stats-row {
  display: flex;
  gap: 10px;
}
.page-search-test .stats-row div {
  line-height: 20px;
  font-size: 16px;
  font-family: HelveticaNeue;
}
.page-search-test .stats-row div:first-child {
  color: #666;
}
.page-search-test .debug-info .json-output {
  position: fixed;
  inset: 100px;
  background-color: #fff;
  overflow: auto;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10;
}
.page-search-test .debug-info .json-output > div {
  border: 0 !important;
}
.page-search-test .debug-info .json-output > button {
  position: fixed;
  top: 120px;
  right: 120px;
  z-index: 11;
}
.page-search-test .json-output.product-debug-info {
  position: fixed;
  inset: 100px;
  background-color: #fff;
  overflow: auto;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10;
}
.page-search-test .json-output.product-debug-info > div {
  border: 0 !important;
}
.page-search-test .json-output.product-debug-info > button {
  position: fixed;
  top: 120px;
  right: 120px;
  z-index: 11;
}
.page-search .toolbar .MuiTextField-root {
  width: 300px;
}
.page-search .toolbar .MuiButton-root {
  margin-left: 10px;
}
.page-search .grid th, .page-search .grid td {
  padding: 5px;
}
.page-search .grid td:first-child {
  width: 200px;
}
.page-search .grid th:first-child {
  width: 200px;
}
.page-search .grid b:first-child {
  margin-right: 5px;
}
.page-search .grid em {
  font-weight: bold;
  font-style: normal;
}

.dialog-query-builder {
  max-width: 900px;
  max-height: 600px;
  width: 100%;
}
.dialog-query-builder .MuiButton-root {
  margin-top: 10px;
}
.page-analysis-scheme .toolbar {
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
}
.page-analysis-scheme .toolbar .MuiButton-root {
  margin-right: 10px;
}
.page-analysis-scheme .toolbar nav {
  margin-left: auto;
}
.page-analysis-scheme .toolbar .MuiTextField-root {
  margin-left: 20px;
}
.page-analysis-scheme .toolbar .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}
.page-analysis-scheme .grid th span {
  line-height: 27px;
  margin-right: 7px;
}
.page-analysis-scheme .grid th a {
  margin-left: auto;
  float: right;
  line-height: 27px;
  color: #000;
  text-decoration: underline;
}
.page-analysis-scheme .grid td, .page-analysis-scheme .grid th {
  padding: 7px;
}
.page-analysis-scheme .grid td:last-child, .page-analysis-scheme .grid th:last-child {
  width: 70px;
  vertical-align: middle;
  text-align: center;
}
.page-analysis-scheme .grid .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}
.page-analysis-scheme .grid .MuiTextField-root .MuiInputBase-root input {
  padding: 5px;
}
.page-analysis-scheme .grid .MuiTextField-root + .MuiTextField-root {
  margin-left: 10px;
}
.page-analysis-scheme .tag {
  margin-top: 0;
  line-height: 27px;
  text-transform: uppercase;
}
.page-analysis-scheme .tag a {
  vertical-align: -6px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 7px;
  color: #000;
}
.page-analysis-scheme .tag a .icon {
  width: 14px;
  height: 14px;
  margin: 0;
  vertical-align: top;
}
.page-analysis-scheme .download-all {
  margin-top: 15px;
  text-align: right;
}
.page-analysis-scheme .download-all a {
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #000;
  text-decoration: underline;
}
.page-tags .toolbar .MuiTextField-root {
  margin-left: 30px;
}
.page-tags .grid td, .page-tags .grid th {
  width: 1px;
}
.page-tags .grid th a.sort {
  margin-left: auto;
  font-size: 10px;
  float: right;
  vertical-align: bottom;
  color: #000;
}
.page-tags .grid td.name, .page-tags .grid th.name,
.page-tags .grid td.description, .page-tags .grid th.description,
.page-tags .grid td.url, .page-tags .grid th.url {
  width: 33%;
}
.page-tags .grid td.created {
  white-space: nowrap;
}
.page-tags .grid td.editing {
  padding: 8px;
}
.page-tags .grid td.editing .MuiTextField-root .MuiInputBase-root {
  margin-top: 0;
}
.page-tags .grid th div {
  display: flex;
}
.page-tags .grid th a {
  margin-left: 10px;
  color: #000;
}
.page-tags .grid th a:nth-child(1) {
  margin-left: auto;
}
.page-tags .grid th a.active {
  text-decoration: underline;
}
.page-tags .pagination {
  margin-top: 30px;
}
.page-tags .tags-toolbar {
  height: 80px;
  position: fixed;
  bottom: -100px;
  left: 0;
  right: 0;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 10;
  transition: bottom 0.2s;
}
.page-tags .tags-toolbar.active {
  bottom: 0;
}
.page-tags .tags-toolbar > div {
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  text-align: right;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 960px) {
  .page-tags .tags-toolbar > div {
    padding: 0 50px;
  }
}
@media screen and (min-width: 1600px) {
  .page-tags .tags-toolbar > div {
    max-width: 1440px;
  }
}
.page-tags .tags-toolbar button {
  margin-left: 15px;
}

.new-tag button {
  margin-top: 15px;
}
.new-tag textarea {
  max-height: 400px;
}

.dialog-merge .tag-list {
  margin-top: 15px;
}
.dialog-merge label {
  font-family: HelveticaNeue;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: block;
}

.dialog-tag-export h3 {
  margin-bottom: 10px;
}
.dialog-tag-export hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.dialog-tag-export label {
  display: block;
}
.dialog-tag-export label .MuiTypography-root {
  vertical-align: -5px !important;
}